import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const newMentorOnboardingColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>
    }
  },
  {
    name: 'name',
    label: 'Name and Details',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          zIndex: 100
        }
      }),
      customBodyRender: (v, x) => (
        <ListItem sx={{ mx: -3 }}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography>
                  <b>{v}</b>, {x?.rowData[3]} years
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography component="span" color="text.primary">
                  {x?.rowData[4]} at {x?.rowData[5]}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  Experience: {x?.rowData[6]}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  Mentoring Experience: {x?.rowData[12]}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  <span>{x?.rowData[7] === 'Other' ? x?.rowData[8] : x?.rowData[7]}, </span>
                  <span> ({x?.rowData[9] === 'Other' ? x?.rowData[10] : x?.rowData[9]})</span>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }
  },
  {
    name: 'age',
    label: 'Age',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'clientName',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'ethnic',
    options: {
      display: false
    }
  },
  {
    name: 'otherEthnic',
    options: {
      display: false
    }
  },
  {
    name: 'pronoun',
    options: {
      display: false
    }
  },
  {
    name: 'otherPronoun',
    options: {
      display: false
    }
  },
  {
    name: 'otherLeadershipExperience',
    options: {
      display: false
    }
  },
  {
    name: 'mentoringExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherAreaOfExpertise',
    options: {
      display: false
    }
  },
  {
    name: 'linkedin',
    label: 'LinkedIn Profile',
    options: {
      sort: true,
      customBodyRender: (v, x) => <a href={v}>{v}</a>
    }
  },
  {
    name: 'leadershipExperience',
    label: 'Leadership Experience Level',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v === 'Other' ? x?.rowData[11] : v}</Typography>
    }
  },
  {
    name: 'experienceAs',
    label: 'Entrepreneur or Employee',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v}</Typography>
    }
  },
  {
    name: 'enjoyAsMentor',
    label: 'Favorite Aspects of Mentoring',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{marginLeft: -25}}>
          {v?.map((enjoyAsMentor) => (
            <li>
              <Typography fontSize="small" sx={{ minWidth: '240px', mr: -2 }}>
                {enjoyAsMentor}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'areaOfExpertise',
    label: 'Areas of Expertise',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{marginLeft: -20}}>
          {v?.map((area) => (
            <li>
              <Typography fontSize="small" sx={{ mr: -2 }}>
                {area === 'Other' ? x?.rowData[13] : area}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'personality',
    label: 'Personality',
    options: {
      customBodyRender: (v, x) => (
        <ul style={{marginLeft: -15}}>
          {v?.map((personality) => (
            <li>
              <Typography fontSize="small">{personality}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  }
];

export default newMentorOnboardingColumns;
