import { combineReducers, Reducer } from 'redux';
import { MatchesReducer } from './matches';
import { MentorshipSessions } from './mentorshipSessions';
import { MentorsReducer } from './mentors';
import { MenteesReducer } from './mentees';
import { ClientsReducer } from './clients';
import { SurveysReducer } from './surveys';
import { SurveysResponseReducer } from './surveysResponse';
import { UsersReducer } from './users';
import { CoacheesReducer } from './coachees';
import { CoachesReducer } from './coaches';
import { GroupsReducer } from './groups';
import { CoachingSessions } from './coachingSessions';
import { ContentReducer } from './content';
import { TasksReducer } from './tasks';

export const allReducers: Reducer = combineReducers({
  matches: MatchesReducer,
  sessions: MentorshipSessions,
  coachingSessions: CoachingSessions,
  mentors: MentorsReducer,
  mentees: MenteesReducer,
  coachees: CoacheesReducer,
  coaches: CoachesReducer,
  clients: ClientsReducer,
  surveys: SurveysReducer,
  surveysResponse: SurveysResponseReducer,
  users: UsersReducer,
  groups: GroupsReducer,
  content: ContentReducer,
  tasks: TasksReducer
});
