import { Rating, Typography } from '@mui/material';
import React from 'react';

const meetAndGreetColumns = [
  {
    name: 'date',
    label: '',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  { name: 'name', label: '' },
  { name: 'email', label: '' },
  { name: 'match', label: '' },
  {
    name: 'rating',
    label: '',
    options: {
      sort: true,
      customBodyRender: (v, x) => {
        return <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />;
      }
    }
  },
  { name: 'reason', label: '' },
  { name: 'options', label: '' },
  { name: 'continue', label: '' }
];

export default meetAndGreetColumns;
