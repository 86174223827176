import api from "../../services/api";


export enum MatchActionTypes {
  CREATE = "MATCH_CREATE",
  FETCH = "MATCH_FETCH",
  FILTER = "MATCH_FILTER",
  UPDATE = "MATCH_UPDATE",
  DIFF = "MATCH_DIFF"
}

// action creators
export function createMatch(match) {
  return function(dispatch) {
    return api.post("/matches", match)
      .then(({ data }) => {
      dispatch({
        type: MatchActionTypes.CREATE,
        payload: data
      });
    });
  }
  
};


export function fetchCurrentMatches() {
  return async function(dispatch) {
    return api.get("/matches")
      .then(({ data }) => {
      dispatch({
        type: MatchActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function updateMatch(match) {
  return function(dispatch) {
    return api.patch("/matches", match)
      .then(({ data }) => {
      dispatch({
        type: MatchActionTypes.UPDATE,
        payload: data
      });
    });
  }
  
}

export function fetchMatchesInconsistency() {
  return async function(dispatch) {
    return api.get("/matches/diff")
      .then(({ data }) => {
      dispatch({
        type: MatchActionTypes.DIFF,
        payload: data
      });
    });
  };
}


// selectors
export const getMatches = (state) => state.matches.matches;
export const getMatchesByClient = (state, id) => state.matches.matches.filter(match => match.clientId === id);
export const getMatchesInconsistency = (state) => state.matches.matchdiff;