import { AnyAction } from 'redux';
import { ClientActionTypes } from '../actions/clients';

const initialState = {
  clients: []
};

export function ClientsReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case ClientActionTypes.CREATE:
      return {
        ...state,
        clients: [action.payload, ...state.clients]
      };

    case ClientActionTypes.FETCH:
      return {
        ...state,
        clients: action.payload
      };

    case ClientActionTypes.UPDATE:
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === action.payload.id
            ? {
                ...client,
                startDate: action.payload.startDate.toString(),
                endDate: action.payload.endDate.toString(),
                status: action.payload.status,
                contactName: action.payload.contactName,
                contactEmail: action.payload.contactEmail
              }
            : client
        )
      };

    default:
      return state;
  }
}
