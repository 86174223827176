import { AnyAction } from 'redux';
import { MenteeActionTypes } from '../actions/mentees';

const initialState = {
  mentees: []
};

export function MenteesReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case MenteeActionTypes.CREATE:
      return {
        ...state,
        mentees: [state.mentees, action.payload]
      };

    case MenteeActionTypes.FETCH:
      return {
        ...state,
        mentees: action.payload
      };

    case MenteeActionTypes.UPDATE:
      return {
        ...state,
        mentees: state.mentees.map((mentee) => {
          if (mentee.id === action.payload.id) {
            mentee.status = action.payload.status;
            mentee.note = action.payload.note;
          }
          return mentee;
        })
      };

    case MenteeActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        mentees: state.mentees.map((mentee) => {
          if (mentee.userId === action.payload.id) {
            mentee.email = action.payload.email;
          }
          return mentee;
        })
      };

    case MenteeActionTypes.UPDATE_LINKEDIN:
      return {
        ...state,
        mentees: state.mentees.map((mentee) => {
          if (mentee.userId === action.payload.id) {
            mentee.linkedin = action.payload.linkedin;
          }
          return mentee;
        })
      };

    default:
      return state;
  }
}
