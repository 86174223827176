export enum ConfigKeyType {
  MeetAndGreetNotesTemplate = 'MEET_AND_GREET_NOTES_TEMPLATE',
  RecurringNotesTemplate = 'RECURRING_NOTES_TEMPLATE',
  FinalNotesTemplate = 'FINAL_NOTES_TEMPLATE',
  OneOnOneTemplate = 'ONE_ON_ONE_NOTES_TEMPLATE'
}

export enum FileObjectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}
