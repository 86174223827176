import { Rating, Typography } from '@mui/material';
import React from 'react';

const midCohortGroupColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{v}</Typography>
            <Typography fontSize="small">{x?.rowData[3]}</Typography>
            <Typography fontSize="small">
              {x?.rowData[7]} at {x?.rowData[8]}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'email',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherSlackChannel',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherAreasImproved',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'experienceRating',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'assignedReadings',
    label: 'Assigned Readings Helpful?*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ mr: -2 }} fontSize="small">
              {v}{' '}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'workspace',
    label: 'Workspace Comments*',
    options: {
      hint: 'Have you found the workspace helpful? Please share any feedback on how we can make it better for you, if need be.',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ width: '200px', ml: -2 }} fontSize="small">
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'slackChannel',
    label: 'Slack/Teams Channel Needed?*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v === 'Other' ? x?.rowData[4] : v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'changes',
    label: 'Coaching Journey Updates*',
    options: {
      hint: 'As you are going through the coaching journey, how are things changing for you?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ width: '200px', mx: -2 }} fontSize="small">
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'newStrengths',
    label: 'New Strengths Developed*',
    options: {
      hint: 'Are there new strengths you have developed from the experience so far?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ width: '200px', mr: -1 }} fontSize="small">
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'benefits',
    label: 'Enjoyed Most So Far*',
    options: {
      hint: 'What is a thing you have benefited from and/or enjoyed the most so far?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ width: '200px', mr: -1 }} fontSize="small">
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'areasImproved',
    label: 'Areas Improved*',
    options: {
      hint: 'In what areas do you feel you have improved in?',
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -35 }}>
          {v?.map((leadershipChallenge) => (
            <li>
              <Typography fontSize="small" sx={{ width: '150px' }}>
                {leadershipChallenge === 'Other' ? x?.rowData[5] : leadershipChallenge}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'coachRating',
    label: 'Ratings*',
    options: {
      sort: true,
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">Coach Rating:</Typography>
            <Rating max={10} sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
            <Typography fontSize="small">Overall Experience:</Typography>
            <Rating max={5} sx={{ ml: -1 }} name="read-only" value={parseInt(x?.rowData[6])} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'coachSupport',
    label: 'Coach Support*',
    options: {
      hint: 'Is there anything the coach can do to help support you?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ width: '180px', mx: -2 }} fontSize="small">
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'coachImprovement',
    label: 'Coach Improvement*',
    options: {
      hint: 'Is there anything the coach can do differently?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'locelleImprovement',
    label: 'Locelle/BestHuman Improvement*',
    options: {
      hint: 'Is there anything Locelle (Besthuman team) can do to improve or enhance your experience?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'portalImprovement',
    label: 'Portal Improvement',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  }
];

export default midCohortGroupColumns;
