import * as React from "react";
import * as ReactDOM from "react-dom";
import  App  from "./entry";
import * as serviceWorker from "./serviceWorker";

// import * as dotenv from 'dotenv';
// import * as path from "path";

// dotenv.config();
// console.log("[dotenv]", path);
console.log("[INDEX ENV]", process.env);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
