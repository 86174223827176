import { Typography } from "@mui/material";

const finalSurveyMentorColumns = [
  {
    name: 'date', label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => (<Typography fontSize='small'>{v?.substring(0, 10)}</Typography>),
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          zIndex: 100
        }
      }),
    },
  },
  { name: 'name', label: 'Name' },
  { name: 'email', label: 'Email' },
  { name: 'match', label: 'Match' },
  { name: 'grown', label: 'Have You Grown?' },
  { name: 'menteeFeedback', label: 'Mentee Feedback' },
  { name: 'locelleImprovement', label: 'Locelle to Improve' },
  { name: 'locelleWell', label: 'Things Improved' },
  { name: 'support', label: 'Support' },
  { name: 'availability', label: 'Availability' },
  { name: 'otherAvailability', label: 'Other' },
];

export default finalSurveyMentorColumns;
