import * as React from 'react';
import { List, ListItem, ListItemText, Avatar, Divider, Typography, ListItemAvatar, Grid } from '@mui/material/';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCurrentUsers, getUsers } from '../../../redux/actions/users';
import { Colors, UserStatus } from '../../../enums/enums';
import { format, parseISO } from 'date-fns';

export default function NewUsersListDashBoard() {
  const dispatch = useDispatch();
  const users = useSelector(getUsers)?.filter((u) => u.status === UserStatus.New);

  React.useEffect(() => {
    dispatch(fetchCurrentUsers());
  }, [dispatch]);

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: 350, overflow: 'auto' }}>
      {users.map((user) => (
        <Link to={`/users/${user.id}`} style={{ textDecoration: 'none' }} target="_blank">
          <ListItem key={user.id}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <ListItemAvatar sx={{ position: 'top' }}>
                  <Avatar sx={{ bgcolor: Colors.TextElevated }}>{user.name.charAt(0)}</Avatar>
                </ListItemAvatar>
              </Grid>
              <Grid item xs={5}>
                <ListItemText
                  sx={{ mr: 3, ml: -3 }}
                  primary={user.name}
                  secondary={
                    <Typography component="span" variant="body2" color="text.primary">
                      {user.email}
                    </Typography>
                  }
                />
                <ListItemText
                  sx={{ ml: -3 }}
                  primary="Signed up:"
                  secondary={
                    <Typography color="text.primary">
                      {format(parseISO(user.createdAt), 'MMM dd, yyyy - h:mm a')}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={5}>
                <ListItemText
                  sx={{ mt: 7 }}
                  primary="Company"
                  secondary={<Typography color="text.primary">{user?.company}</Typography>}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider variant="inset" component="li" />
        </Link>
      ))}
      {users.length === 0 && (
        <ListItem>
          <Typography align="center">No results</Typography>
        </ListItem>
      )}
    </List>
  );
}
