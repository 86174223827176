import {  Card, CardActions, CardContent, CardHeader, Chip, Select } from "@material-ui/core";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

const itemsFromBackend = [
  { id: '12345', content: {
    "name": "Daniele Vilela",
  "email": "daniele.vilela17@locelle.com",
  "title": "Software Developer",
  "company": "Locelle",
  "clientId": "66d467c1-8cc8-4b60-b7c2-733691499e38",
  "linkedin": "danielevilela",
  "experience": "9-10",
  "timezone": "PST",
  "status": "NEW"} },
  { id: '12345', content: {
    "name": "Daniele Vilela",
    "email": "daniele.vilela17@locelle.com",
    "title": "Software Developer",
    "company": "Locelle",
    "clientId": "66d467c1-8cc8-4b60-b7c2-733691499e38",
    "linkedin": "danielevilela",
    "experience": "9-10",
    "timezone": "PST",
    "status": "NEW",
  } },
  { id: '12345', content: {
    "name": "Av Takhar",
    "email": "avtak18@hotmail.com",
    "title": "Digital Engagement Community Manager",
    "company": "Locelle",
    "clientId": "d5a47e2d-6afa-4410-8a96-254b797ef93a",
    "linkedin": null,
    "experience": "1-3",
    "timezone": "PST",
    "status": "NEW",
  } },
  { id: '12345', content: {
    "name": "Av Takhar",
    "email": "avtak18@hotmail.com",
    "title": "Digital Engagement Community Manager",
    "company": "Locelle",
    "clientId": "d5a47e2d-6afa-4410-8a96-254b797ef93a",
    "linkedin": null,
    "experience": "1-3",
    "timezone": "PST",
    "status": "NEW",
  } },
  { id: '12345', content:{
    "name": "Arielle Poon",
    "email": "arielle.poon@locelle.com",
    "title": "Program Success Manager",
    "company": "Locelle",
    "clientId": "d5a47e2d-6afa-4410-8a96-254b797ef93a",
    "linkedin": null,
    "experience": "1-3",
    "timezone": "PST",
    "status": "NEW",
  } }
];

const columnsFromBackend = {
  '12345': {
    name: "Queue",
    items: itemsFromBackend
  },
  '123456': {
    name: "To do",
    items: []
  },
  '123457': {
    name: "In Progress",
    items: []
  },
  '123458': {
    name: "Done",
    items: []
  }
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};

function TasksBoard() {
  const [columns, setColumns] = React.useState(columnsFromBackend);
  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
              key={columnId}
            >
              <h2>{column.name}</h2>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                          padding: 4,
                          width: 250,
                          minHeight: 500
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      // padding: 16,
                                      margin: "0 0 8px 0",
                                      minHeight: "50px",
                                      backgroundColor: snapshot.isDragging
                                        ? "#263B4A"
                                        : "#FEFEFE",
                                      // color: "white",
                                      ...provided.draggableProps.style
                                    }}
                                  >
                                    <CardHeader 
                                    avatar={
                                      <Chip label="NEW" />
                                    } 
                                    title={`${item.content.name},  ${item.content.title} ${item.content.company} `}
                                    subheader={`Experience: ${item.content.experience}`}
                                    />
                                    <CardContent>
                                      Paired with: 
                                      <Select
                                        native
                                        value={10}
                                        inputProps={{
                                          name: 'age',
                                          id: 'age-native-simple',
                                        }}
                                      >
                                        <option aria-label="None" value="" />
                                        <option value={10}>Mentor 1</option>
                                        <option value={20}>Mentor 2</option>
                                        <option value={30}>Mentor 3</option>
                                      </Select>
                                    </CardContent>
                                    <CardActions title="tete">
                                        <CheckCircleOutlineRoundedIcon style={{ color: 'green'}} onClick={() => alert(localStorage.getItem('ADMIN:USER'))} />
                                        <Select
                                        native
                                        value={10}
                                        inputProps={{
                                          name: 'age',
                                          id: 'age-native-simple',
                                        }}
                                      >
                                        <option aria-label="None" value="" />
                                        <option value={10}>Mentor 1</option>
                                        <option value={20}>Mentor 2</option>
                                        <option value={30}>Mentor 3</option>
                                      </Select>
                                    </CardActions>
                                  </Card>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
}

export default TasksBoard;
