import { AnyAction } from 'redux';
import { TasksActionTypes } from '../actions/tasks';

const initialState = {
  tasks: [],
  groupTaskResponses: [],
  userTasksResponses: []
};

export function TasksReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case TasksActionTypes.FETCH:
      return {
        ...state,
        tasks: action.payload
      };

    case TasksActionTypes.FETCH_TASKS_BY_GROUP:
      return {
        ...state,
        tasks: action.payload
      };

      case TasksActionTypes.FETCH_RESPONSES_BY_GROUP:
      return {
        ...state,
        tasksResponses: action.payload
      };

    case TasksActionTypes.FETCH_TASK_RESPONSES_BY_ID:
      return {
        ...state,
        userTasksResponses: action.payload.map((taskResponse) => {
          return {
            date: taskResponse.createdAt,
            email: taskResponse.email,
            name: taskResponse.name,
            title: taskResponse.title,
            company: taskResponse.company,
            ...taskResponse.response
          };
        })
      };

    default:
      return state;
  }
}
