export enum SessionType {
  Single = 'single',
  Bulk = 'bulk'
}
export enum SessionStatus {
  Confirmed = 'CONFIRMED',
  Feedback = 'FEEDBACK',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum SessionStage {
  MeetAndGreet = 'Meet And Greet',
  Recurring = 'Recurring Session',
  Final = 'Final Session',
}
