import api from '../../services/api';
import { toast } from 'react-toastify';
import { ClientStatus } from '../../enums/enums';

export enum ClientActionTypes {
  CREATE = 'CLIENT_CREATE',
  FETCH = 'CLIENT_FETCH',
  UPDATE = 'CLIENT_UPDATE'
}

// action creators
export function createClient(client) {
  return function (dispatch) {
    return api
      .post('/clients', client)
      .then(({ data }) => {
        dispatch({
          type: ClientActionTypes.CREATE,
          payload: data
        });
        toast.success('Successfully created client');
      })
      .catch((error) => {
        console.error('Error creating client', error);
        toast.error(
          `Error creating client.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function fetchCurrentClients() {
  return function (dispatch) {
    return api.get('/clients').then(({ data }) => {
      dispatch({
        type: ClientActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function updateClient(client: {
  id: string;
  status: string;
  startDate: string;
  endDate: string;
  contactName?: string;
  contactEmail?: string;
}) {
  return function (dispatch) {
    return api
      .patch('/clients', client)
      .then(({ data }) => {
        dispatch({
          type: ClientActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated Client');
      })
      .catch((e) => toast.error('Error updating Client', e));
  };
}

// selectors
export const getClients = (state) => state.clients.clients;
export const getActiveClients = (state) => state.clients.clients?.filter((c) => c.status === ClientStatus.Active);
