import api from "../../services/api";


export enum SurveyActionTypes {
  CREATE = "SURVEY_CREATE",
  FETCH = "SURVEY_FETCH",
  FETCH_RESPONSE = "FETCH_USER_RESPONSES",
  FETCH_USER_RESPONSES = "SURVEY_RESPONSE",
  FETCH_RESPONSE_TABLE = "SURVEY_RESPONSE_TABLE",
  FETCH_CLIENT_SURVEY_RESPONSES = "FETCH_CLIENT_SURVEY_RESPONSES",
}


export function fetchCurrentSurveysResponse() {
  return function(dispatch) {
    return api.get("/surveys/response")
      .then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_RESPONSE,
        payload: data
      });
    });
  };
}

export function fetchSurveysResponsesBySurveyId(surveyId: string) {
  return function(dispatch) {
    return api.get(`/surveys/response/${surveyId}`)
      .then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_RESPONSE_TABLE,
        payload: data
      });
    });
  };
}

export function fetchSurveysResponsesByUserId(userId: string) {
  return function(dispatch) {
    return api.get(`/surveys/response-user/${userId}`)
      .then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_USER_RESPONSES,
        payload: data
      });
    });
  };
}

export function fetchAllSurveysResponsesByClient(clientId: string) {
  return function(dispatch) {
    return api.get(`/surveys/response-by-client/${clientId}`)
      .then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_CLIENT_SURVEY_RESPONSES,
        payload: data
      });
    });
  };
}


// selectors
export const getSurveysResponse = (state) => state.surveysResponse.surveysResponse;
export const getAllSurveysResponsesByClient = (state) => state.surveysResponse.allSurveyResponsesByClient;
export const getSurveysResponseByEmail = (state, email) => state.surveysResponse.surveysResponse.filter(response => response.email === email);
export const getSurveysResponseByUserId = (state, id) => state.surveysResponse.surveysResponse.filter(response => response.userId === id);