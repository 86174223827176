import api from '../../services/api';

export enum TasksActionTypes {
  FETCH = 'FETCH_TASKS',
  FETCH_TASK_RESPONSES_BY_ID = 'FETCH_TASK_RESPONSES_BY_ID',
  FETCH_RESPONSES_BY_GROUP = 'FETCH_RESPONSES_BY_GROUP',
  FETCH_TASKS_BY_GROUP = 'FETCH_TASKS_BY_GROUP'
}

const baseUrl = '/tasks';

export function fetchTasks() {
  return function (dispatch) {
    return api.get(`${baseUrl}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchGroupTasks(groupId: string) {
  return function (dispatch) {
    return api.get(`${baseUrl}/${groupId}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH_TASKS_BY_GROUP,
        payload: data
      });
    });
  };
}

export function fetchGroupTasksResponses(groupId: string) {
  return function (dispatch) {
    return api.get(`${baseUrl}/group-responses/${groupId}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH_RESPONSES_BY_GROUP,
        payload: data
      });
    });
  };
}


export function fetchResponsesByTaskId(taskId: string) {
  return function (dispatch) {
    return api.get(`${baseUrl}/responses/${taskId}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH_TASK_RESPONSES_BY_ID,
        payload: data
      });
    });
  };
}

// selectors
export const getTasks = (state) => state.tasks.tasks;
export const getTasksByGroupId = (state) => state.tasks.tasks;
export const getGroupTaskResponses = (state) => state.tasks.tasksResponses;
export const getUserPopulatedTaskResponses = (state) => state.tasks.userTasksResponses;
