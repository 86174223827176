import api from "../../services/api";


export enum MentorActionTypes {
  CREATE = "MENTOR_CREATE",
  FETCH = "MENTOR_FETCH",
  UPDATE = "MENTOR_UPDATE",
  UPDATE_EMAIL = "MENTOR_UPDATE_EMAIL",
  UPDATE_LINKEDIN = "MENTOR_UPDATE_LINKEDIN"
}

// action creators
export function createMentor(match) {
  return function(dispatch) {
    return api.post("/mentors", match)
      .then(({ data }) => {
      dispatch({
        type: MentorActionTypes.CREATE,
        payload: data
      });
    });
  }
  
};


export function fetchCurrentMentors() {
  return function(dispatch) {
    return api.get("/mentors")
      .then(({ data }) => {
        console.log("REDUX MENTOR", data);
      dispatch({
        type: MentorActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function updateMentor(mentor: {id: string, status: string, note: string}){
  return function (dispatch){
    return api.patch('/mentors', mentor)
    .then(({data}) => {
      dispatch({
        type: MentorActionTypes.UPDATE,
        payload: data
      })
    })
  }
}




// selectors
export const getMentors = (state) => state.mentors.mentors;
export const getActiveMentors = (state) => state.mentors.mentors.filter(mentor => mentor.status === "ACTIVE" || mentor.status === "NEW");
export const getMentorMeetingId = (state, id) => state.mentors.mentors.filter(mentor => mentor.id === id).map(mentor => mentor.meetingId)[0];
