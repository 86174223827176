import { Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createClient } from '../../../../redux/actions/clients';
import moment from 'moment';
import { Colors } from '../../../../enums/enums';

type Props = {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
};

const CreateClientForm = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    dispatch(createClient(data));
    onClose(false);
  };

  const formatDate = (value: string): string => {
    let formattedDate = '';
    if (value) {
      formattedDate = moment(value).format('MM/DD/YYYY');
    }
    return formattedDate || value;
  };

  return (
    <div>
      <Dialog onClose={() => onClose(false)} open={open}>
        <DialogTitle sx={{ backgroundColor: Colors.BackgroundDrawer, color: Colors.TextElevated }} id="simple-dialog-title">
          Create Client
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ backgroundColor: Colors.BackgroundMain }}>
            <Stack direction="column" spacing={2}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                color="success"
                label="Name"
                {...register('name', { required: true })}
                style={{ width: '300px' }}
              />
              {errors.name && <span>This field is required</span>}
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Contact Name"
                variant="outlined"
                color="success"
                {...register('contactName', { required: true })}
                style={{ width: '300px' }}
              />
              {errors.contactName && <span>This field is required</span>}
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Contact Email"
                {...register('contactEmail', { required: true })}
                style={{ width: '300px' }}
              />
              {errors.contactEmail && <span>This field is required</span>}
              <TextField
                type="date"
                label="Start Date"
                variant="outlined"
                color="success"
                InputLabelProps={{ shrink: true }}
                {...register('startDate', {
                  required: true,
                  setValueAs: (value) => formatDate(value)
                })}
                style={{ width: 300, height: '56px', paddingTop: '5px' }}
                required={true}
              />
              <TextField
                type="date"
                label="End Date"
                variant="outlined"
                color="success"
                InputLabelProps={{ shrink: true }}
                {...register('endDate', {
                  required: true,
                  setValueAs: (value) => formatDate(value)
                })}
                style={{ width: 300, height: '56px', paddingTop: '5px' }}
                required={true}
              />
            </Stack>
            <DialogActions>
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ mt: 2, mr: -1, backgroundColor: Colors.ButtonGreen }}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateClientForm;
