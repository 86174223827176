import { AnyAction } from 'redux';
import { CoacheesActionTypes } from '../actions/coachees';

const initialState = {
  coachees: [],
  coacheesUsers: []
};

export function CoacheesReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case CoacheesActionTypes.FETCH:
      return {
        ...state,
        coachees: action.payload
      };

    case CoacheesActionTypes.FETCH_COACHEES_USERS:
      return {
        ...state,
        coacheesUsers: action.payload
      };

    case CoacheesActionTypes.UPDATE:
      return {
        ...state,
        coacheesUsers: state.coacheesUsers.map((coacheesUser) => {
          if (coacheesUser.id === action.payload.id) {
            coacheesUser.disabled = action.payload.disabled;
            coacheesUser.note = action.payload.note;
            coacheesUser.linkedin = action.payload.linkedin;
            coacheesUser.email2 = action.payload.email2;
          }
          return coacheesUser;
        })
      };

    case CoacheesActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        coacheesUsers: state.coacheesUsers.map((coacheeUser) => {
          if (coacheeUser.userId === action.payload.id) {
            coacheeUser.email = action.payload.email;
          }
          return coacheeUser;
        })
      };

    default:
      return state;
  }
}
