import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { Chip, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { fetchCurrentClients, getClients, updateClient } from '../../../../redux/actions/clients';
import { format, parseISO } from 'date-fns';
import Avatar from '@mui/material/Avatar';
import { Autocomplete, Button, Card, Stack, TextField, Typography } from '@mui/material';
import ClientProfile from './ClientProfile';
import { useHistory, useParams } from 'react-router-dom';
import { fetchCurrentUsers } from '../../../../redux/actions/users';
import CreateClientForm from './CreateClientForm';
import { Colors } from '../../../../enums/enums';

const Clients: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const clients = useSelector(getClients);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [editing, setEditing] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [filter, setFilter] = React.useState(null);
  const statusColors = { ACTIVE: Colors.Active, INACTIVE: Colors.Inactive };
  const [openCreateClient, setOpenCreateClient] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(fetchCurrentClients());
    dispatch(fetchCurrentUsers());
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleEndDate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEndDate(event.target.value as string);
  };
  const handleStartDate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStartDate(event.target.value as string);
  };

  const columns = [
    {
      name: 'name',
      label: ' ',
      options: {
        empty: true,
        customBodyRender: (v) => <Avatar sx={{ bgcolor: Colors.TextElevated }}>{v?.charAt(0)}</Avatar>
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true
      }
    },
    {
      name: 'code',
      label: 'Code'
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
      options: {
        customBodyRender: (value, x) => {
          if (editing && x.rowIndex === selectedRow) {
            return <TextField value={startDate || value?.substring(0, 10)} onChange={handleStartDate} />;
          }
          if (value) {
            const parsedData = parseISO(value);
            return <span>{format(parsedData, 'MMM dd, yyyy').toString()}</span>;
          }
        }
      }
    },
    {
      name: 'endDate',
      label: 'End Date',
      options: {
        customBodyRender: (value, x) => {
          if (editing && x.rowIndex === selectedRow) {
            return <TextField value={endDate || value?.substring(0, 10)} onChange={handleEndDate} />;
          }
          if (value) {
            const parsedData = parseISO(value);
            return <span>{format(parsedData, 'MMM dd, yyyy').toString()}</span>;
          }
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        //   empty: true,
        customBodyRender: (v, x) =>
          editing && x.rowIndex === selectedRow ? (
            <Select variant="outlined" value={`${status || v}`} onChange={handleChange}>
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
            </Select>
          ) : (
            <Chip style={{ backgroundColor: statusColors[v], textTransform: 'none' }} label={v} />
          )
      }
    },
    {
      name: 'id',
      label: ' ',
      options: {
        //   empty: true,
        customBodyRender: (v, x) => (
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              // setSelectedId(v);
              setSelectedRow(x.rowIndex);
              setEditing(!editing);
            }}
          >
            {editing && x.rowIndex === selectedRow ? <span style={{ color: 'darkslateblue' }}>Cancel</span> : 'Edit'}
          </Button>
        )
      }
    }
  ];

  if (editing) {
    columns.push({
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (v, x) =>
          x.rowIndex === selectedRow && (
            <Button
              sx={{ backgroundColor: Colors.ButtonGreen, color: 'white' }}
              variant="outlined"
              color="success"
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                x.rowData.push(status);
                console.log(x);
                setEditing(false);
                dispatch(updateClient({ id: v, status, startDate, endDate }));
                setStartDate(null);
                setEndDate(null);
                setStatus(null);
              }}
            >
              <span>Save</span>
            </Button>
          )
      }
    });
  }

  const options = {
    filter: true,
    filterType: 'dropdown',
    selectableRows: 'none',
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => clients[d.dataIndex]._id); // array of all ids to to be deleted
      console.log(rowsDeleted, idsToDelete);
    }
  };

  return (
    <div style={{ padding: '8px', marginTop: '-15px' }}>
      <CreateClientForm open={openCreateClient} onClose={setOpenCreateClient} />
      <Card elevation={6} style={{ marginTop: '10px' }}>
        <Stack direction="row" spacing={3}>
          <Autocomplete
            id="clients"
            onChange={(event, newInputValue: { id: string; name: string }) => {
              // setStage(newInputValue.description);
              setFilter(newInputValue);
              history.push(`/clients/${newInputValue.id}`);
            }}
            options={clients}
            getOptionLabel={(option: { id: string; name: string }) => option.name}
            style={{ width: 200, marginTop: '10px', marginLeft: '10px' }}
            renderInput={(params) => (
              <TextField {...params} label="Filter by Client" variant="outlined" color="success" />
            )}
          />
          <Button
            variant="contained"
            style={{ background: Colors.ButtonGreen, marginTop: '10px', height: '56px' }}
            onClick={() => setOpenCreateClient(!openCreateClient)}
          >
            Create New Client
          </Button>
        </Stack>

        <br />
      </Card>
      <br />
      {filter || id ? (
        <ClientProfile client={filter} />
      ) : (
        <MUIDataTable
          title={<h2 style={{ color: Colors.TextElevated }}>All Clients</h2>}
          data={clients}
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
};

export default Clients;
