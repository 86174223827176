import * as React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

export default function Instructions() {
  const [showInfo, setShowInfo] = useState(true);
  return (
    <>
      <Tooltip title="Show/hide info" arrow>
        <IconButton
          sx={{ mt: 2 }}
          color="primary"
          onClick={() => {
            !showInfo ? setShowInfo(true) : setShowInfo(false);
          }}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      {showInfo && (
        <Stack sx={{ mt: 1, ml: 3 }} spacing={2}>
          <Typography>Please only make changes approved by Humaira.</Typography>
          <Typography>
            Any changes you make will be live for all participants. Double triple check to make sure they are necessary.
          </Typography>
          <Typography>
            <em>Save changes before closing a template or they will be lost.</em>
          </Typography>
        </Stack>
      )}
    </>
  );
}
