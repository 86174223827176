import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import menteeColumns from '../../../dtos/OnboardingMenteeSurveyResponse';
import mentorColumns from '../../../dtos/OnboardingMentorSurveyResponse';
import finalSurveyMentorColumns from '../../../dtos/FinalSurveyMentorResponses';
import finalSurveyMenteeColumns from '../../../dtos/FinalSurveyMenteeResponses';
import meetAndGreetColumns from '../../../dtos/MeetAndGreetSurveyResponses';
import { Colors, SurveyType, UserType } from '../../../enums/enums';
import onboardingAllColumns from '../../../dtos/OnboardingAllSurveyResponse';
import newMenteeOnboardingColumns from '../../../dtos/NewMenteeSurveyResponse';
import newCoacheeOnboardingColumns from '../../../dtos/NewCoacheeSurveyResponse';
import newMentorOnboardingColumns from '../../../dtos/NewMentorSurveyResponse';
import newCoachOnboardingColumns from '../../../dtos/NewCoachSurveyResponse';
import meetAndGreetAllColumns from '../../../dtos/MeetAndGreetSurveyResponsesAll';
import finalSurveyCoacheeColumns from '../../../dtos/FinalSurveyCoacheeResponses';
import midCohortSurveyCoacheeColumns from '../../../dtos/MidCohortSurveyCoacheeResponses';
import termsAndConditionsColumns from '../../../dtos/TermsAndConditionsResponse';
import { Typography } from '@mui/material';

interface ISurveyResponse {
  surveyId: string;
  surveyType: string;
  target: UserType;
  checked?: boolean;
  surveyResponses: any;
}

const SurveysResponse: React.FC<ISurveyResponse> = ({ surveyId, checked, surveyType, target, surveyResponses }) => {
  console.log('SURVEY RESPONSES', surveyResponses);

  let tableColumnsFound;
  switch (surveyType) {
    case SurveyType.OnboardingMentee:
      tableColumnsFound = menteeColumns;
      break;
    case SurveyType.OnboardingMentor:
      tableColumnsFound = mentorColumns;
      break;
    case SurveyType.FinalSurvey:
      tableColumnsFound =
        UserType.Mentors === target
          ? finalSurveyMentorColumns
          : UserType.Mentees === target
          ? finalSurveyMenteeColumns
          : finalSurveyCoacheeColumns;
      break;
    case SurveyType.MeetAndGreetFeedback:
      tableColumnsFound = UserType.All === target ? meetAndGreetAllColumns : meetAndGreetColumns;
      break;
    case SurveyType.Onboarding:
      tableColumnsFound = onboardingAllColumns;
      break;
    case SurveyType.NewMenteeSurvey:
      tableColumnsFound = newMenteeOnboardingColumns;
      break;
    case SurveyType.NewMentorSurvey:
      tableColumnsFound = newMentorOnboardingColumns;
      break;
    case SurveyType.NewCoacheeSurvey:
      tableColumnsFound = newCoacheeOnboardingColumns;
      break;
    case SurveyType.NewCoachSurvey:
      tableColumnsFound = newCoachOnboardingColumns;
      break;
    case SurveyType.MidCohortSurvey:
      tableColumnsFound = midCohortSurveyCoacheeColumns;
      break;
    case SurveyType.TermsAndConditionsAgreement:
      tableColumnsFound = termsAndConditionsColumns;
      break;
  }

  const options = {
    filter: true,
    selectableRows: 'none',
    // pagination: false,
    download: true,
    print: true,
    // search: false,
    viewColumns: false,
    stickyHeader: true
  };

  return (
    <div style={{ padding: '8px', width: '100%' }}>
      {surveyResponses?.length ? (
        <Typography
          sx={{ mb: 2, mr: 2, fontWeight: 'bold', fontSize: '1.2rem', color: Colors.ButtonGreen }}
          textAlign="right"
        >
          Total Responses: {surveyResponses?.length}
        </Typography>
      ) : (
        <></>
      )}
      <MUIDataTable
        // title={"Surveys"}
        data={surveyResponses}
        columns={tableColumnsFound}
        options={options}
      />
    </div>
  );
};

export default SurveysResponse;
