import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Button,
  Stack,
  DialogContent,
  Typography,
  CardMedia,
  Card,
  CardContent,
  Select,
  InputLabel,
  Box,
  FormControl,
  OutlinedInput,
  Chip
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogActions, DialogTitle, FormLabel, MenuItem } from '@material-ui/core';
import { Colors } from '../../../../enums/enums';
import { FileObjectStatus } from '../config.enum';
import { InputFileUpload } from '../../../shared/input-file-upload/InputFileUpload';
import api from '../../../../services/api';
import { fetchContentFiles, updateContent } from '../../../../redux/actions/content';
import { toast } from 'react-toastify';
import { fetchCurrentClients, getClients } from '../../../../redux/actions/clients';
import IClient from '../../mentorship/clients/IClient';

interface Props {
  open: boolean;
  fileObject: {
    id: string;
    status: FileObjectStatus;
    title: string;
    thumbnailName: string;
    thumbnailUrl: string;
    clientIds: string[];
  };
  clients: any;
  onClose: Dispatch<SetStateAction<boolean>>;
}

interface FileUploadResponse {
  filename: string;
  filepath: string;
}

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const EditLearningTool = (props: Props) => {
  const dispatch = useDispatch();
  const { open, fileObject, clients, onClose } = props;
  const [thumbnailUrl, setThumbnailUrl] = React.useState(null);
  const [thumbnailName, setThumbnailName] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [thumbnail, setThumbnail] = React.useState(false);

  const resourceClients = clients?.filter((client: IClient) => fileObject?.clientIds?.includes(client?.id));

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: fileObject?.id,
      title: fileObject?.title,
      status: fileObject?.status,
      clients: resourceClients
    }
  });

  useEffect(() => {
    setStatus(fileObject?.status);
    setTitle(fileObject?.title);
    if (fileObject?.thumbnailUrl) {
      setThumbnail(true);
    }
    reset({
      id: fileObject?.id,
      title: fileObject?.title,
      status: fileObject?.status,
      clients: resourceClients
    });
  }, [fileObject, reset]);

  const onSubmit = () => {
    let clientIds = getValues('clients')?.map((c) => c.id);
    if (!clientIds?.length) {
      clientIds = null;
    }

    try {
      dispatch(updateContent(fileObject?.id, title, status, thumbnailName, thumbnailUrl, clientIds));
      handleClose();
    } catch (e) {
      console.error('Error updating learning tool: ', e);
    }
  };

  const trimInput = (event, fieldName): void => {
    const trimmedValue = event?.target?.value?.trim();
    setValue(fieldName, trimmedValue);
  };

  const handleSelectedFile = (files: FileList): void => {
    if (files?.length) {
      const file = files.item(0);
      console.log('File selected', file);

      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      api
        .post<FileUploadResponse>(`/content/thumbnail`, formData, config)
        .then(({ data }) => {
          dispatch(fetchContentFiles());
          console.log('API response on thumbnail upload: ', data);
          toast.success('Successfully uploaded thumbnail image');
          setThumbnailUrl(data?.filepath);
          setThumbnailName(data?.filename);
          setThumbnail(true);
        })
        .catch((error) => {
          console.error('Error uploading thumbnail file', error);
          toast.error(
            `Error uploading thumbnail file.\nMessage: ${
              error.response?.data?.message || error.request || error.message
            }`
          );
        });
    }
  };

  const deleteThumbnailFile = () => {
    const thumbnailFileName = thumbnailName || fileObject?.thumbnailName;
    return api
      .delete(`/content/thumbnail/${fileObject?.id}`, {
        data: {
          thumbnailFileName
        }
      })
      .then((deleteResponse) => {
        console.log('API response on thumbnail upload: ', deleteResponse);
        toast.success('Successfully deleted thumbnail image');
        setThumbnailUrl(null);
        setThumbnailName(null);
        setThumbnail(false);
      })
      .catch((error) => {
        console.error('Error deleting thumbnail file', error);
        toast.error(
          `Error deleting thumbnail file.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };

  console.log('FILE OBJECT ', fileObject);

  const handleClose = () => {
    onClose(!open);
    dispatch(fetchContentFiles());
    setThumbnailUrl(null);
    setThumbnailName(null);
    setThumbnail(false);
    reset();
  };

  return (
    <Dialog disableEnforceFocus open={open} onClose={handleClose} maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle style={{ color: Colors.TextElevated, backgroundColor: Colors.BackgroundDrawer }}>
          Update Learning Tool
        </DialogTitle>
        <DialogContent style={{ width: '900px', backgroundColor: Colors.BackgroundMain }}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: '80%' }}
                    label="Title"
                    color="success"
                    placeholder="enter title for this learning tool"
                    value={title}
                    onChange={(e) => setTitle(e?.target?.value)}
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'title')}
                  />
                )}
                name="title"
                control={control}
              />

              <Box sx={{ mt: 8 }}>
                <FormControl>
                  <InputLabel shrink>Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={fileObject?.status}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={status}
                        label="Status"
                        onChange={(event) => setStatus(event?.target?.value as FileObjectStatus)}
                        sx={{ width: '150px' }}
                      >
                        <MenuItem value={FileObjectStatus.Active}>{FileObjectStatus.Active}</MenuItem>
                        <MenuItem value={FileObjectStatus.Inactive}>{FileObjectStatus.Inactive}</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            </Stack>
            <Stack>
              <Controller
                name="clients"
                control={control}
                render={({ field }) => (
                  <FormControl variant="outlined">
                    <InputLabel shrink id="clients">
                      Display to selected Clients only
                    </InputLabel>
                    <Select
                      labelId="clients"
                      multiple
                      defaultValue={resourceClients}
                      color="success"
                      sx={{ width: '500px', minHeight: '60px' }}
                      notched={true}
                      input={<OutlinedInput id="clients" label="Display to Selected Clients only" />}
                      {...field}
                      onChange={(event, addedClient: any) => {
                        const addedClientId = addedClient.props?.value?.id;
                        if (!getValues('clients')?.find((client) => client.id === addedClientId)) {
                          field.onChange(event);
                        }
                      }}
                      renderValue={(selectedClients) => {
                        const clients = selectedClients ? selectedClients : resourceClients;
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5
                            }}
                          >
                            {clients.map((client: IClient) => (
                              <Chip
                                key={client?.id}
                                label={client?.name}
                                onDelete={() => {
                                  const filterClients = getValues('clients')?.filter((c) => c.id !== client.id);
                                  setValue('clients', filterClients);
                                }}
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            ))}
                          </Box>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {clients?.map((client) => (
                        <MenuItem key={client?.id} value={client}>
                          {client?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Typography fontSize="small" color="grey">
                Leave empty to make this Learning Tool visible to all users
              </Typography>
            </Stack>

            {!thumbnail && (
              <>
                <FormLabel>Upload thumbnail image </FormLabel>
                <InputFileUpload onFileSelect={handleSelectedFile} />
              </>
            )}

            <>
              <Typography sx={{ color: Colors.TextElevated, mt: 4 }}>Learning Tool preview:</Typography>
              <Card sx={{ width: '330px', height: '270px' }}>
                <CardMedia
                  sx={{ height: '180px' }}
                  component="img"
                  image={
                    thumbnail
                      ? thumbnailUrl || fileObject?.thumbnailUrl
                      : 'http://static1.squarespace.com/static/5898e29c725e25e7132d5a5a/58aa11bc9656ca13c4524c68/58aa11e99656ca13c45253e2/1487540713345/600x400-Image-Placeholder.jpg?format=original'
                  }
                />
                <CardContent>
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ color: Colors.TextElevated }}
                  >
                    {title || fileObject?.title}
                  </Typography>
                </CardContent>
              </Card>
            </>

            {thumbnail && (
              <>
                <FormLabel>
                  Current thumbnail: {thumbnailName}
                  <br />
                  <br />
                  To upload a different thumbnail image delete the existing one first
                </FormLabel>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ height: '40px', width: '230px' }}
                  onClick={deleteThumbnailFile}
                >
                  Delete thumbnail file
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>

        <DialogActions style={{ backgroundColor: Colors.BackgroundDrawer, paddingTop: '20px' }}>
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'grey', mr: 2, mb: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.ButtonGreen, mr: 2, mb: 2 }}
              type="submit"
            >
              Save
            </Button>
          </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditLearningTool;
