import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import { fetchCurrentClients, getClients } from '../../../../redux/actions/clients';
import { useEffect, useState } from 'react';
import { Colors } from '../../../../enums/enums';
import { fetchCurrentUsers, getUsers } from '../../../../redux/actions/users';
import ActiveCohortsTable from './ActiveCohortsTable';
import InactiveCohortsTable from './InactiveCohortsTable';
import CreateCohort from './CreateCohort';

const Cohorts: React.FC = () => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const clients = useSelector(getClients);
  const [openCreateCohort, setOpenCreateCohort] = useState(false);
  const [showInactiveCohorts, setShowInactiveCohorts] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrentUsers());
    dispatch(fetchCurrentClients());
  }, [dispatch]);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 500,
          backgroundColor: Colors.BackgroundDrawer,
          width: '100%',
          height: '89px',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
        }}
      >
        <Button
          variant="contained"
          color="success"
          sx={{
            background: Colors.ButtonGreen,
            mt: 2,
            mb: 3,
            height: '56px',
            mx: 3
          }}
          onClick={() => setOpenCreateCohort(!openCreateCohort)}
        >
          Create New Cohort
        </Button>

        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={showInactiveCohorts}
              onChange={() => {
                setShowInactiveCohorts(!showInactiveCohorts);
              }}
            />
          }
          label="Show Inactive Cohorts Table"
        />
      </Box>

      <CreateCohort
        clients={clients}
        users={users}
        open={openCreateCohort}
        onClose={() => setOpenCreateCohort(false)}
      />

      <Box sx={{ pl: '8px', mb: 3, mt: 12 }}>
        <ActiveCohortsTable users={users} clients={clients} />
      </Box>

      {showInactiveCohorts && (
        <Box sx={{ pl: '8px', mb: 3 }}>
          <InactiveCohortsTable users={users} clients={clients} />
        </Box>
      )}
    </>
  );
};

export default Cohorts;
