import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import {
  fetchGroupTasks,
  fetchGroupTasksResponses,
  getGroupTaskResponses,
  getTasksByGroupId
} from '../../../../../redux/actions/tasks';
import { useParams } from 'react-router-dom';
import TaskResponse from './TaskResponse';
import { Colors } from '../../../../../enums/enums';
import { UsersTaskResponses } from './enums/UsersTaskResponses';

const GroupTasks: React.FC = () => {
  const dispatch = useDispatch();
  const groupTasks = useSelector(getTasksByGroupId);
  const { groupName } = useParams<{ groupName: string }>();
  const { groupId } = useParams<{ groupId: string }>();

  const groupTasksResponses: UsersTaskResponses[] = useSelector(getGroupTaskResponses);

  React.useEffect(() => {
    dispatch(fetchGroupTasks(groupId));
    dispatch(fetchGroupTasksResponses(groupId));
  }, [dispatch, groupId]);

  const taskIdsWithResponses = groupTasksResponses?.map((tr) => tr.taskId);
  const filteredTasksToDisplay = groupTasks?.filter((gt) => taskIdsWithResponses?.includes(gt.id));

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },

    {
      name: 'taskName',
      label: 'Task Name',
      options: {
        sort: true
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        sort: true
      }
    },
    {
      name: 'id',
      label: 'Number of Responses Submitted',
      options: {
        sort: true,
        customBodyRender: (v, x) => (
          <Typography fontSize="small">{groupTasksResponses?.filter((tr) => tr.taskId === v).length}</Typography>
        )
      }
    }
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    // search: false,
    viewColumns: false,
    selectableRows: 'none',
    expandableRows: true,
    fixedHeader: true,
    renderExpandableRow: (rowData: any[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      return (
        <>
          <tr>
            <td colSpan={12}>
              <TaskResponse groupId={groupId} taskName={rowData[1]} taskId={rowData[0]} />
            </td>
          </tr>
        </>
      );
    },

    grouping: {
      columnIndexes: [1, 3]
    },
    onRowClick: (rowData, rowState) => {
      console.log(rowData, rowState);
    }
  };

  return (
    <Box sx={{ p: 1, pt: 2 }}>
      <MUIDataTable
        title={
          <Typography sx={{ color: Colors.TextElevated, fontSize: '1.2rem' }}>
            <b>{groupName}</b> group Tasks
          </Typography>
        }
        data={filteredTasksToDisplay}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

export default GroupTasks;
