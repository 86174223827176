import { Button, Dialog } from '@mui/material';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import IClient from './IClient';
import { updateClient } from '../../../../redux/actions/clients';
import { useDispatch } from 'react-redux';
import { Colors } from '../../../../enums/enums';

interface Props {
  open: boolean;
  client: IClient;
  onClose: Dispatch<SetStateAction<boolean>>;
}
const EditClientDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { open, client, onClose } = props;
  const [contactName, setContactName] = useState(client?.contactName);
  const [contactEmail, setContactEmail] = useState(client?.contactEmail);
  const submitForm = async () => {
    try {
      dispatch(
        updateClient({
          id: client.id,
          status: client.status,
          startDate: client.startDate,
          endDate: client.endDate,
          contactName,
          contactEmail
        })
      );
      console.log('New name', contactName);
      console.log('New email', contactEmail);
    } catch (error) {
      toast.error('Error updating client');
    }
    onClose(!open);
  };
  const handleClose = () => {
    onClose(!open);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: Colors.TextElevated, backgroundColor: Colors.BackgroundDrawer }}>
        Update Client
      </DialogTitle>
      <DialogContent style={{ marginBottom: '20px', backgroundColor: Colors.BackgroundMain }}>
        <TextField
          sx={{ width: '350px', mt: 5 }}
          id="contactName"
          label="Contact Name"
          variant="outlined"
          color="success"
          defaultValue={client?.contactName}
          onChange={(e) => {
            setContactName(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogContent style={{ marginBottom: '20px' }}>
        <TextField
          style={{ width: '350px' }}
          id="contactEmail"
          label="Contact Email"
          variant="outlined"
          color="success"
          defaultValue={client?.contactEmail}
          onChange={(e) => {
            setContactEmail(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          sx={{ backgroundColor: Colors.ButtonGreen, mr: 2, mb: 2 }}
          onClick={submitForm}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClientDialog;
