import * as React from 'react';
import { Route, withRouter, RouteComponentProps, Switch } from './utils/Router';
import NewLogin from './components/admin/NewLogin';
import routes from './routes';
import CircularProgress from '@mui/material/CircularProgress';
import './css/global.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AppProps extends RouteComponentProps<any> {
  userNeedsLogin?: boolean;
  loading?: boolean;
}
/**
 * The client-side Locelle admin application
 */
const App: React.FC<AppProps> = (props) => {
  const { loading } = props;
  const [isSignedIn, setSignedIn] = React.useState(false);
  const updateLoginStatus = (val) => {
    console.log('[updateLoginStatus]', val);
    setSignedIn(val);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const token = localStorage.getItem('ADMIN:token');
  if (!token) {
    console.log('[isSignedIn]', isSignedIn);
    return <NewLogin load={props} isSignedIn={updateLoginStatus} />;
  } else {
    return (
      <div>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return (
                    <route.layout {...props} isSignedIn={updateLoginStatus}>
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
        </Switch>
        <ToastContainer />
      </div>
    );
  }
};

export default withRouter(App);
