export enum SessionType {
  Single = 'single',
  Bulk = 'bulk'
}
export enum SessionStatus {
  Confirmed = 'CONFIRMED',
  Feedback = 'FEEDBACK',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum SessionStage {
  MeetAndGreet = 'Meet And Greet',
  Recurring = 'Recurring Session',
  Final = 'Final Session',
  Individual = 'Individual Session'
}

export enum SessionIntervalWeek {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6
}
export enum SessionBreakDurationWeek {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10
}

export enum SessionDurationMinutes {
  Thirty = 30,
  FortyFive = 45,
  Sixty = 60,
  Ninety = 90,
  HundredFive = 105,
  HundredTwenty = 120
}