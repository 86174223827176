import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Avatar, ListItemAvatar } from '@mui/material/';
import { Colors } from '../enums/enums';

const onboardingAllColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>
    }
  },
  {
    name: 'firstName',
    label: 'Name',
    options: {
      sort: true,
      customBodyRender: (v, x) => (
        <ListItem sx={{ mx: -2 }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: Colors.ButtonGreen }}>{v?.charAt(0)}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${v} ${x.rowData[11] || ''}`}
            secondary={
              <React.Fragment>
                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                  {x?.rowData[3]} at {x?.rowData[5]}
                </Typography>
                <Typography variant="body2">Experience: {x?.rowData[4]} years</Typography>
                {x?.rowData[8] !== 'N/A' && x?.rowData[8]?.includes('linkedin.com') ? (
                  <Box sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }}>
                    <a href={v} target="_blank" rel="noreferrer nofollow">
                      {x?.rowData[8]}
                    </a>
                  </Box>
                ) : (
                  <Typography sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }} fontSize="small">
                    {v}
                  </Typography>
                )}
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'age',
    label: 'Age'
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
            {x?.rowData[14] && (
              <>
                <Typography fontSize="small" sx={{ color: 'grey' }}>
                  Alternate email:
                </Typography>
                <Typography fontSize="small" sx={{ color: 'grey' }}>
                  {x?.rowData[14]}
                </Typography>
              </>
            )}
          </>
        );
      }
    }
  },
  {
    name: 'linkedin',
    label: 'LinkedIn Profile',
    options: {
      display: false
    }
  },
  {
    name: 'city',
    label: 'Location',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v},</Typography>
            <Typography fontSize="small">{x?.rowData[10]}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'country',
    label: 'Country',
    options: {
      display: false
    }
  },
  {
    name: 'lastName',
    label: 'LastName',
    options: {
      display: false
    }
  },
  {
    name: 'timezone',
    label: 'Timezone',
    options: {
      display: true
    }
  },
  {
    name: 'careerGoals',
    label: 'Career Goals',
    options: {
      customBodyRender: (v, x) => <Typography sx={{ minWidth: '200px' }}>{v}</Typography>
    }
  },
  {
    name: 'alternateEmail',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'learningStyle',
    label: 'Learning Style',
    options: {
      customBodyRender: (v, x) => <Typography sx={{ minWidth: '200px' }}>{v}</Typography>
    }
  },
  {
    name: 'accessibility',
    label: 'Accessibility Needs',
    options: {
      customBodyRender: (v, x) => <Typography sx={{ minWidth: '200px' }}>{v}</Typography>
    }
  }
];

export default onboardingAllColumns;
