import { Typography } from "@mui/material";

const finalSurveyMenteeColumns =  [
  {
    name: 'date', label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => (<Typography fontSize='small'>{v?.substring(0, 10)}</Typography>),
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
        }
      }),
    },
  },
  { name: 'name', 
    label: 'Name',
    options: {
    sort: true,
    setCellProps: () => ({
      style: {
        whiteSpace: "nowrap",
      }
    }),    
    customBodyRender: (v, x) => {
      return (<>
        <Typography fontSize='small'>{v}</Typography>
        <Typography fontSize='small'>{x?.rowData[3]}</Typography>
      </>)
    }
    
  },
  },
  { name: 'match', 
    label: 'Match',
    options: {
      sort: true,
      // customBodyRender: (v, x) => (<Typography fontSize='small'>{v.substring(0, 10)}</Typography>),
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
        }
      }),
    },
  },
  { 
    name: 'email', 
    label: '',
    options: {
      display: false
    }
  },
  { name: 'improvement', label: 'Skills/areas of improvement'},
  { name: 'opportunity', label: 'Opportunity'},
  {   
    name: 'thingsImproved', 
    label: 'Things Improved',
    options: {
      customBodyRender: (v, x) => {
        return (<>
          <Typography fontSize='small'>{v}</Typography>
          <Typography fontSize='small'>{x?.rowData[7]}</Typography>
        </>)
      }
    }

  },
  { 
    name: 'otherThingsImproved', 
    label: 'Other', 
    options: {
      display: true
    }
  },
  { 
    name: 'assistance', 
    label: 'Locelle to Improve',
    options: {
      customBodyRender: (v, x) => {
        return (<>
          <Typography fontSize='small'>{v}</Typography>
          <Typography fontSize='small'>{x?.rowData[9]}</Typography>
        </>)
      }
    }
  },
  { 
    name: 'otherAssistance', 
    label: 'Other',
    options: {
      display: true
    }
  },
  { name: 'leadership', label: 'Step into leadership'},
  { name: 'otherLeadership', label: 'Other'},
  { name: 'otherTestimonial', label: 'Testimonial'},
  { name: 'nextCohort', label: 'Next Cohort'},
  { name: 'otherNextCohort', label: 'Other'}
  
];

export default finalSurveyMenteeColumns;
