import { ListItem,  ListItemText, Typography } from "@mui/material";
import React from "react";


let mentorColumns = [

  {
    name: "date",
    label: "Date",
    options: {
      sort: true,
      customBodyRender: (v, x) => (<Typography fontSize='small'>{v?.substring(0, 10)}</Typography>),
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
        }
      }),
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
        }
      }),
      customBodyRender: (v, x) => (
        <ListItem>
          {/* <ListItemAvatar>
            <Avatar sx={{ bgcolor: '#683364' }} >{v.charAt(0)}</Avatar>
          </ListItemAvatar> */}
          <ListItemText
            primary={`${v} (${x?.rowData[18]|| 'n/a'})`}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {x?.rowData[2]}: {/*title */}
                </Typography>
                {` ${x?.rowData[3]} experience`}
                <br />
                {x?.rowData[4]} {/*company */}
                <br />

              </React.Fragment>
            }
          />
        </ListItem>
      )
      //   hint: "?",
      //   setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
    }
  },
  {
    name: "title",
    label: "Title",
    options: {
      display: false,
    }

  },
  {
    name: "experience",
    label: "Experience",
    options: {
      display: false,
    }
  },
  {
    name: "company",
    label: "Company",
    options: {
      display: false,
    }
  },
  {
    name: "age",
    label: "Age"
  },
  {
    name: "linkedin",
    label: "Linkedin"
  },
  {
    name: "email",
    label: "Email",
    options: {
      display: false,
    }
  },
  {
    name: "location",
    label: "Location",
    options: {
      customBodyRender: (v, x) => {
        return (<>
          <Typography fontSize='small'>{v}</Typography>
          <Typography fontSize='small'>{x?.rowData[9]}</Typography>
        </>)
      }
    }
  },

  {
    name: "timezone",
    options: {
      display: false,
    }
  },
  {
    name: "goal",
    label: "Goal",
    options: {
      customBodyRender: (v, x) => (
        <Typography fontSize='small'>{v}</Typography>
      )
    }
  },
  {
    name: "personality",
    label: "Personality",
    options: {
      customBodyRender: (v, x) => (
        <Typography fontSize='small'>{`${v?.join(",\n")}`}</Typography>
      )
    }
  },
  {
    name: "areas",
    label: "Areas",
    options: {
      customBodyRender: (v, x) => (
        <>
          {v?.map(area => (

            <Typography fontSize='small'>{area}</Typography>
          ))}
          {<Typography fontSize='small'>{x?.rowData[13]}</Typography>}

        </>
      )
    }
  },
  {
    name: "otherAreas",
    options: {
      display: false,
    }
  },
  {
    name: "enjoyment",
    label: "Enjoyment",
    options: {
      customBodyRender: (v, x) => (
        <Typography fontSize='small'>{`${v} ${x?.rowData[15]}`}</Typography>
      )
    }
  },
  {
    name: "otherEnjoyment",
    options: {
      display: false,
    }
  },
  {
    name: "company",
    label: "Company",
    options: {
      display: false,
    }
  },
  {
    name: "expertise",
    label: "Expertise",
    options: {
      customBodyRender: (v, x) => (
        <Typography fontSize='small'>{`${v} ${x?.rowData[18]}`}</Typography>
      )
    }
  },
  {
    name: "otherExpertise",
    options: {
      display: false,
    }
  },  
  {
    name: "employment",
    label: "Employment"
  },
  {
    name: "availability",
    label: "Availability",
    options: {
      customBodyRender: (v, x) => (
        <Typography fontSize='small'>{`${v} ${x?.rowData[21]}`}</Typography>
      )
    }
  },
  {
    name: "otherAvailability",
    options: {
      display: false,
    }
  },

];


export default mentorColumns;
