import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Colors } from '../../enums/enums';

interface Props {
  isOpen: boolean;
  title: string;
  message: JSX.Element | string;
  onConfirmBtn: () => void;
  onCancelBtn: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

export const ConfirmationDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle sx={{ backgroundColor: Colors.BackgroundDrawer, mb: 4 }} id="confirm-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer, pr: 3, pb: 2, pt: 2, mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#7e797e', mr: 2 }}
          onClick={props.onCancelBtn}
        >
          {props.cancelBtnText?.toUpperCase() || 'CANCEL'}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={props.onConfirmBtn}
          autoFocus
          sx={{ backgroundColor: Colors.ButtonGreen }}
        >
          {props.confirmBtnText?.toUpperCase() || 'CONFIRM'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
