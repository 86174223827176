import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import OrganizationsIcon from '@material-ui/icons/BusinessOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GridViewIcon from '@mui/icons-material/GridView';
import Logo from '../../assets/logo.png';
import { Colors } from '../../enums/enums';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const drawerWidth = 195;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: Colors.BackgroundMain
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        color: Colors.MenuText
      },
      backgroundColor: Colors.BackgroundDrawer
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      boxShadow:
        '0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%)',
      backgroundColor: Colors.BackgroundDrawer
    },
    content: {
      flexGrow: 1,
      padding: 0,
      width: '100%'
    },
    activeMenuText: {
      fontWeight: 'bolder'
    },
    activeMenuItem: {
      borderLeft: '5px solid #342E40',
      paddingLeft: '11px',
      backgroundColor: Colors.SelectedDrawerBackground,
      color: Colors.MenuText
    },
    hoverMenuItem: {
      '&:hover': {
        borderLeft: '5px solid #342E40',
        paddingLeft: '11px',
        color: Colors.Text
      },
      color: Colors.Text
    }
  })
);

export default function ResponsiveDrawer({ children, window, isSignedIn }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(0);
  const location = useLocation();
  const history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { title: 'Dashboard', icon: <GridViewIcon />, url: '/' },
    { title: 'Clients', icon: <OrganizationsIcon />, url: '/clients' },
    { title: `Users`, icon: <PeopleAltIcon />, url: '/users' },
    { title: 'Cohorts', icon: <Diversity2Icon />, url: '/cohorts' },
    { title: `Sessions`, icon: <EventNoteIcon />, url: '/coaching-sessions-list' },
    { title: `Calendar`, icon: <CalendarMonthIcon />, url: '/coaching-sessions-calendar' },
    { title: 'Config', icon: <SettingsIcon />, url: '/config' }
      // TODO: remove unused menus after removing corresponding components / code
    // { title: 'Surveys-XX', icon: <AssignmentOutlinedIcon />, url: '/surveys' },
    // { title: 'Mentor-XX', icon: <SchoolOutlinedIcon />, url: '/mentors' },
    // { title: 'Mentee-XX', icon: <SupervisedUserCircleOutlinedIcon />, url: '/mentees' },
    // { title: 'Matches-XX', icon: <StarsOutlinedIcon />, url: '/matches', diff: matchDiff },
    // { title: 'Mentorship Sessions-XX', icon: <DateRangeOutlinedIcon />, url: '/sessions' },
    // { title: 'Coach-XX', icon: <SchoolIcon />, url: '/coaches' },
    // { title: 'Coachee-XX', icon: <GroupIcon />, url: '/coachees' }
  ];

  React.useEffect(() => {
    setSelectedMenu(selectedMenu);
    console.log(selectedMenu);
  }, [selectedMenu]);

  const drawer = (
    <div>
      <img
        src={Logo}
        alt="Locelle Logo"
        style={{ marginLeft: '10%', marginTop: '10%', marginBottom: '10%', width: '70%' }}
      />

      <Divider />
      <List>
        {navItems.map((option, index) => (
          <Link key={index} to={option.url} style={{ textDecoration: 'none' }}>
            <ListItem
              button
              key={index}
              className={location.pathname === option.url ? classes.activeMenuItem : classes.hoverMenuItem}
            >
              <ListItemIcon style={{ color: location.pathname === option.url ? Colors.Text : '' }}>
                {navItems[index].icon}
              </ListItemIcon>
              <ListItemText
                primary={<Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{option.title}</Typography>}
              />
            </ListItem>
          </Link>
        ))}
        <Divider />
        <ListItem
          button
          onClick={() => {
            isSignedIn(false);
            localStorage.clear();
            history.push('/');
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
