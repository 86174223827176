import { AnyAction } from 'redux';
import { UserActionTypes } from '../actions/users';

const initialState = {
  users: [],
  user: null,
  errors: []
};

export function UsersReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case UserActionTypes.FETCH:
      return {
        ...state,
        users: action.payload,
        errors: []
      };

    case UserActionTypes.FETCH_USER_BY_ID:
      return {
        ...state,
        user: action.payload,
        errors: []
      };

    case UserActionTypes.USER_UPDATE:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id
            ? {
                ...user,
                email: action.payload.email,
                email2: action.payload.email2,
                name: action.payload.name,
                status: action.payload.status,
                company: action.payload.company,
                title: action.payload.title,
                experience: action.payload.experience,
                linkedin: action.payload.linkedin,
                note: action.payload.note,
                country: action.payload.country,
                city: action.payload.city,
                timezone: action.payload.timezone,
                isCertifiedCoach: action.payload.isCertifiedCoach,
                isFacilitator: action.payload.isFacilitator
              }
            : user
        ),
        errors: []
      };

    case UserActionTypes.ERROR:
      return {
        ...state,
        users: state.users,
        errors: state.errors.push(action.payload)
      };

      case UserActionTypes.DELETE:
          const idOfDeletedUser = action.payload;
          return {
              ...state,
              users: state.users.filter((user) => user.id !== idOfDeletedUser)
          };

    default:
      return state;
  }
}
