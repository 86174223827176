import React from 'react';
import { Rating, Typography } from '@mui/material';

const oneOnOneFinalColumns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{v}</Typography>
            <Typography fontSize="small">{x?.rowData[3]}</Typography>
            <Typography fontSize="small">
              {x?.rowData[5]} at {x?.rowData[6]}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'rating',
    label: 'Rating*',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'email',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherReferProgram',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherValuableAspects',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'otherContinue',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'skillsGained',
    label: 'Skills/knowledge Gained*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '150px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'challengeExamples',
    label: 'Challenges Examples*',
    options: {
      hint: 'Can you provide examples of specific challenges or situations where the sessions/program helped you make better decisions or achieve positive outcomes?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '150px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'changesNoticed',
    label: 'Positive Changes*',
    options: {
      hint: 'Have you noticed any changes in your approach to situations as a result of the program?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '150px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'valuableAspects',
    label: 'Program Valuable Aspects*',
    options: {
      hint: 'What aspects of the leadership program did you find most valuable or impactful?',
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -20, marginRight: -20 }}>
          {v?.map((valuableAspect) => (
            <li>
              <Typography fontSize="small" sx={{ width: '150px' }}>
                {valuableAspect === 'Other' ? x?.rowData[7] : valuableAspect}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'gainedInsights',
    label: 'Gained Insights*',
    options: {
      hint: 'n what areas do you feel you have learned something new or have gained insights to improve?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '180px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'growth',
    label: 'Program helped you grow?*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'referProgram',
    label: 'Refer Program to Colleagues?*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v === 'Other' ? x?.rowData[4] : v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'continue',
    label: 'Continue?*',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v === 'Other' ? x?.rowData[8] : v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'improvement',
    label: 'BestHuman Improvement',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'attendWorkshops',
    label: 'Want to Attend Workshops?',
    options: {
      hint: 'Would you like to attend virtual monthly leadership topics workshops led by experts?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small" sx={{ width: '180px', mr: -2 }}>
              {v}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'testimonial',
    label: 'Testimonial',
    options: {
      hint: 'Do you have a testimonial you would like to share about your Mentor/Coach and overall experience?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  }
];

export default oneOnOneFinalColumns;
