import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ICoachingSession } from '../mentorship/coaching-sessions/coaching.models';
import { Colors } from '../../../enums/enums';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentGroups, getGroups } from '../../../redux/actions/groups';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '900px',
  minHeight: '500px',
  transform: 'translate(-50%, -50%)',
  bgcolor: Colors.BackgroundMain,
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};

interface Props {
  data: { open: boolean; sessionList: ICoachingSession[]; filename: string };
  onClose: () => void;
}

export default function UsagesModal(props: Props) {
  const dispatch = useDispatch();
  const currentGroups = useSelector(getGroups);

  React.useEffect(() => {
    dispatch(fetchCurrentGroups());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'groupId',
      label: 'Group Name',
      options: {
        customBodyRender: (v, x) => <Typography>{currentGroups?.find((g) => g.id === v)?.description}</Typography>
      }
    },
    {
      name: 'topic',
      label: 'Session Topic',
      options: {
        customBodyRender: (v, x) => <Typography>{v}</Typography>
      }
    },
    {
      name: 'date',
      label: 'Date and Time',
      options: {
        customBodyRender: (v, x) => <Typography>{new Date(v).toLocaleString()}</Typography>
      }
    },
    {
      name: 'stage',
      label: 'Stage',
      options: {
        customBodyRender: (v, x) => <Typography>{v}</Typography>
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (v, x) => <Typography>{v}</Typography>
      }
    }
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    selectableRows: false,
    scrollableY: true
  };

  return (
    <div>
      <Modal keepMounted open={props.data.open} onClose={props.onClose}>
        <Box sx={style}>
          <Typography sx={{ color: Colors.Text, mb: 1 }} variant="h5">
            File Usages
          </Typography>
          <Typography sx={{ color: 'grey', mb: 5 }}>
            File <b>{props.data.filename}</b> is currently attached to the following sessions:
          </Typography>
          <MUIDataTable editable={false} data={props.data.sessionList} columns={columns} options={options} />
        </Box>
      </Modal>
    </div>
  );
}
