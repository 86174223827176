import * as React from 'react';
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} />),
  Check: forwardRef((props, ref) => <Check {...props}  />),
  Clear: forwardRef((props, ref) => <Clear {...props}  />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props}  />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props}  />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props}  />),
  Export: forwardRef((props, ref) => <SaveAlt {...props}  />),
  Filter: forwardRef((props, ref) => <FilterList {...props}  />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props}  />),
  LastPage: forwardRef((props, ref) => <LastPage {...props}  />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props}  />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props}  />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props}  />),
  Search: forwardRef((props, ref) => <Search {...props}  />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props}  />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props}  />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props}  />)
};
const MaterialTableIcons = props => {
  return <MaterialTable icons={tableIcons} {...props} />;
};
export default MaterialTableIcons;
