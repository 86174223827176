import api from '../../services/api';
import { MenteeActionTypes } from './mentees';
import { MentorActionTypes } from './mentors';
import { toast } from 'react-toastify';
import {UserStatus, UserType} from '../../enums/enums';
import { CoacheesActionTypes } from './coachees';
import { CoachesActionTypes } from './coaches';
import {CoachingSessionActionTypes} from "./coachingSessions";

export enum UserActionTypes {
  FETCH = 'USERS_FETCH',
  FETCH_USER_BY_ID = 'FETCH_USER_BY_ID',
  USER_UPDATE = 'USER_UPDATE',
  DELETE = 'DELETE_USER',
  ERROR = 'USER_ERROR'
}

export function fetchCurrentUsers() {
  return function (dispatch) {
    return api.get('/users/all').then(({ data }) => {
      dispatch({
        type: UserActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchUserById(userId) {
  return function (dispatch) {
    return api.get(`/users/${userId}`).then(({ data }) => {
      dispatch({
        type: UserActionTypes.FETCH_USER_BY_ID,
        payload: data
      });
    });
  };
}

export function updateUserEmail(type, userId, newEmail, isCoach?) {
  return function (dispatch) {
    return api
      .patch('/users/profile', { id: userId, email: newEmail })
      .then(({ data }) => {
        toast.success('Successfully updated email');
        dispatch({
          type:
            UserType.Mentors === type
              ? MentorActionTypes.UPDATE_EMAIL
              : UserType.Mentees === type
              ? MenteeActionTypes.UPDATE_EMAIL
              : UserType.Coaching === type && !isCoach
              ? CoacheesActionTypes.UPDATE_EMAIL
              : UserType.Coaching === type && isCoach
              ? CoachesActionTypes.UPDATE_EMAIL
              : null,
          payload: { id: userId, email: newEmail }
        });
      })
      .catch((error) => {
        toast.error('Error updating email', error?.message);
      });
  };
}

export function updateUser(
  userId: string,
  name: string,
  status: string,
  email: string,
  email2: string,
  company: string,
  title: string,
  experience: string,
  linkedin: string,
  note: string,
  country: string,
  city: string,
  timezone: string,
  coachFlag: boolean,
  facilitatorFlag: boolean
) {
  return function (dispatch) {
    return api
      .patch('/users/profile', {
        userId,
        name,
        status,
        email,
        email2,
        company,
        title,
        experience,
        linkedin,
        note,
        country,
        city,
        timezone,
        coachFlag,
        facilitatorFlag
      })
      .then(({ data }) => {
        toast.success('Successfully updated user');
        dispatch({
          type: UserActionTypes.USER_UPDATE,
          payload: data
        });
      })
      .catch((error) => {
        toast.error('Error updating user', error?.message);
      });
  };
}

export function approveUserRole(userId, role) {
  return function (dispatch) {
    return api
      .patch(`users/approve-role/${userId}`, { role })
      .then(({ data }) => {
        toast.success('Successfully approved new User Role');
      })
      .catch((error) => {
        toast.error(`Error approving new User Role: ${error?.response?.data?.message}`);
      });
  };
}

export function approveNewUser(userId: string, portalUrl: string, note?: string) {
  return function (dispatch) {
    return api
      .patch(`users//approve-registration/${userId}`, { portalUrl, note })
      .then(({ data }) => {
        toast.success('Successfully approved new User');
      })
      .catch((error) => {
        toast.error(`Error approving new User: ${error?.response?.data?.message}`);
      });
  };
}

export function resendOnboarding(userId, role, clientId) {
  return function (dispatch) {
    return api
      .patch(`users/resend-onboarding/${userId}`, { role, clientId })
      .then(({ data }) => {
        toast.success(`New ${role} onboarding form resent successfully`);
      })
      .catch((error) => {
        toast.error(`Error resending onboarding form: ${error?.response?.data?.message}`);
      });
  };
}
export function updateUserLinkedIn(type, userId, newLinkedIn) {
  let url;

  if (UserType.Mentors === type) {
    url = '/mentors';
  } else if (UserType.Mentees === type) {
    url = '/mentees';
  } else return;

  return function (dispatch) {
    return api
      .patch(url, { id: userId, linkedin: newLinkedIn })
      .then(({ data }) => {
        toast.success('Successfully updated LinkedIn link');
        dispatch({
          type:
            UserType.Mentors === type
              ? MentorActionTypes.UPDATE_LINKEDIN
              : UserType.Mentees === type
              ? MenteeActionTypes.UPDATE_LINKEDIN
              : null,
          payload: { id: userId, linkedin: newLinkedIn }
        });
      })
      .catch((error) => {
        toast.error('Error updating LinkedIn profile', error);
      });
  };
}

export function deleteUser(userId: string) {
    return function (dispatch) {
        const url = `users/${userId}`;
        return api
            .delete(url)
            .then((deleteResponse) => {
                const { data, statusText } = deleteResponse;

                if (1 === data) {
                    dispatch({
                        type: UserActionTypes.DELETE,
                        payload: userId
                    });
                    toast.success('Successfully deleted user');
                } else {
                    toast.error(`Error deleting user.\nMessage: ${statusText}`);
                }
            })
            .catch((error) => {
                console.error('Error deleting user', error);
                toast.error(
                    `Error deleting user.\nMessage: ${error.response?.data?.message || error.request || error.message}`
                );
            });
    };
}export function declineUser(userId: string) {
    return function (dispatch) {
        const url = `users/decline/${userId}`;
        return api
            .delete(url)
            .then((deleteResponse) => {
                const { data, statusText } = deleteResponse;

                if (1 === data) {
                    dispatch({
                        type: UserActionTypes.DELETE,
                        payload: userId
                    });
                    toast.success('Successfully declined user registration');
                } else {
                    toast.error(`Error declining user.\nMessage: ${statusText}`);
                }
            })
            .catch((error) => {
                console.error('Error declining user', error);
                toast.error(
                    `Error declining user.\nMessage: ${error.response?.data?.message || error.request || error.message}`
                );
            });
    };


}

// selectors
export const getUsers = (state) => state.users.users;
export const getActiveUsers = (state) => state.users.users?.filter(u => u.status === UserStatus.Active);
export const getUser = (state) => state.users.user;
export const getUserError = (state, userId) => state.users.errors.find((user) => user.id === userId);