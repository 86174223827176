import {Rating, Typography} from '@mui/material';
import React from "react";

const meetAndGreetAllColumns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  { name: 'name', label: 'Name' },
  {
    name: 'email',
    label: 'Email'
  },
  { name: 'title', label: 'Job Title' },
  {
    name: 'rating',
    label: 'Rating',
    options: {
      sort: true,
      customBodyRender: (v, x) => {
        return (
              <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
        );
      }
    }
  }
];

export default meetAndGreetAllColumns;
