import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  FormControl,
  FormHelperText
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { IUser } from '../../../interfaces/interfaces';
import { deleteUser } from '../../../redux/actions/users';
import { Colors } from '../../../enums/enums';
import { Controller, useForm } from 'react-hook-form';
import ErrorIcon from '@mui/icons-material/Error';
import { useHistory } from 'react-router-dom';

interface Props {
  open: boolean;
  user: IUser;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const DeleteUserDialog = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open, user, onClose } = props;
  const [confirmation, setConfirmation] = useState('');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      confirmation: ''
    }
  });

  const onSubmit = () => {
    try {
      dispatch(deleteUser(user?.id));
      handleClose();
      history.push('/users');
    } catch (e) {
      console.error('Error deleting user: ', e);
    }
  };

  const handleClose = () => {
    onClose(!open);
    reset();
    setConfirmation('');
  };

  return (
    <Dialog sx={{ height: '95%vh' }} open={open} onClose={handleClose} maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ color: '#bd0c0c', backgroundColor: Colors.Inactive }}>Delete user account?</DialogTitle>
        <DialogContent sx={{ width: '700px', height: '300px', backgroundColor: Colors.BackgroundMain, mt: 3 }}>
          <Typography sx={{ mt: 2, fontSize: '1.1rem', fontWeight: 'bold' }}>CAUTION:</Typography>
          <Typography sx={{ my: 2, fontSize: '1rem' }}>
            Deleting this user will permanently remove their account and all associated data. <br /> This action cannot
            be undone.
          </Typography>

          <Typography sx={{ my: 3, fontSize: '1rem' }}>
            To confirm deleting {user?.name}, please enter the word
            <span style={{ color: '#bd0c0c', fontSize: '1.1rem', fontWeight: 'bold' }}> delete</span> in the field
            below:
          </Typography>

          <FormControl>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirmation word *"
                  color="success"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                />
              )}
              name="confirmation"
              control={control}
              rules={{ required: true, validate: (value) => value === 'delete' }}
              defaultValue=""
            />
            {errors.confirmation && (
              <FormHelperText sx={{ color: 'red', display: 'flex', alignItems: 'center', mt: 1, ml: -0.5 }}>
                <ErrorIcon fontSize="small" color="error" sx={{ mr: 0.5 }} />
                <Typography fontSize="small" sx={{ mt: 0.3 }}>
                  Confirmation word is required
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>

        <DialogActions style={{ backgroundColor: Colors.Inactive }}>
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'grey', mr: 2, mb: 2, mt: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="contained" color="error" sx={{ mr: 2, mb: 2, mt: 2 }} type="submit">
              Delete User
            </Button>
          </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteUserDialog;
