import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, Divider, Stack, Typography, TextField, RadioGroup, Radio } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { SessionStage, SessionStatus, SessionType } from '../coaching.enums';
import { SESSION_BREAK_DURATIONS, SESSION_DURATIONS, SESSION_INTERVALS, STAGES } from '../coaching.constants';
import { isNullOrUndefined } from '../../../../../utils/common-utils';
import { toast } from 'react-toastify';
import { ICoachingSession, ICoachingSessionBulk } from '../coaching.models';
import WarningIcon from '@material-ui/icons/Warning';
import { SessionBreakInterface, SessionDurationInterface, SessionIntervalInterface } from '../coaching.interface';
import {
  createBulkCoachingSession,
  createCoachingSession,
  fetchAllCoachingSessions
} from '../../../../../redux/actions/coachingSessions';
import { IGroup } from '../../../../../interfaces/interfaces';
import { Colors } from '../../../../../enums/enums';

interface Props {
  groups: IGroup[];
  sessions: ICoachingSession[];
  openDialog: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const CreateCoachingSessionEventForm = (props: Props) => {
  const groups = props.groups;
  const coachingSessions = props.sessions;
  const [type, setType] = useState<SessionType>(SessionType.Single);
  const [duplicatedSessions, setDuplicatedSessions] = useState([]);
  const [breakValid, setBreakValid] = useState(true);
  const [hasBreakAfter, setHasBreakAfter] = useState(false);
  const openDialog = props.openDialog;
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dispatch = useDispatch();
  const timezones = moment.tz.names();
  const time = (zone) => moment.tz(zone).format('Z');
  const [tz, setTz] = useState({ timezone: currentTimezone, name: currentTimezone });

  console.log('CURRENT TIMEZONE', currentTimezone);
  const {
    handleSubmit,
    watch,
    getValues,
    control,
    reset,
    resetField,
    formState: { errors }
  } = useForm({
    defaultValues: {
      group: null,
      stage: null,
      recSessionsCount: 1,
      addMeetAndGreet: true,
      addFinalSession: true,
      date: '',
      sessionDuration: SESSION_DURATIONS[0],
      interval: SESSION_INTERVALS[1],
      breakDuration: null,
      breakAfter: 0,
      timezone: { timezone: currentTimezone, name: currentTimezone }
    }
  });

  useEffect(() => {
    watch(() => setDuplicatedSessions([]));
  }, [watch]);

  const onSubmit = async (data: any) => {
    try {
      const values = getValues();
      const selectedTimezone = moment.tz(values.date, tz.timezone);
      const vancouverTimezone = selectedTimezone.clone().tz('America/Vancouver');
      const finalSelectedTime = vancouverTimezone.toString();
      const isSingleType = SessionType.Single === type;
      if (!isSingleType) {
        const isBreakValid = breakValidation(values);
        if (!isBreakValid) {
          toast.warning('Invalid number of sessions before break');
          setBreakValid(false);
          setTimeout(() => {
            setBreakValid(true);
          }, 7000);
          return;
        } else {
          setBreakValid(true);
        }
      }

      if (!duplicatedSessions?.length) {
        const duplicates = getStageDuplications(values, isSingleType);
        console.log('duplicatedSessions: ', duplicates);
        if (duplicates?.length) {
          toast.warning('Duplicated session(s) found');
          return;
        }
      } else {
        setDuplicatedSessions([]);
      }
      toast.info('Request submitted, please wait...');

      if (isSingleType) {
        const session = {
          groupId: values.group.id,
          stage: values.stage?.name,
          status: 'CONFIRMED',
          date: finalSelectedTime,
          topic: '',
          sessionDuration: values.sessionDuration,
          token: '',
          url: ''
        };
        console.log('PAYLOAD SINGLE>>', session);
        dispatch(createCoachingSession(session));
        dispatch(fetchAllCoachingSessions());
      } else {
        const bulkSessions: ICoachingSessionBulk = {
          groupId: values.group.id,
          status: 'CONFIRMED',
          date: finalSelectedTime,
          topic: '',
          sessionDuration: values.sessionDuration,
          token: '',
          url: '',
          stageMeetAndGreet: values.addMeetAndGreet,
          stageFinalSession: values.addFinalSession,
          recurringSessionsCount: values.recSessionsCount,
          interval: values.interval,
          breakDuration: values.breakDuration,
          breakAfter: values.breakAfter
        };

        console.log('PAYLOAD BULK>>', bulkSessions);
        await dispatch(createBulkCoachingSession(bulkSessions));
        await dispatch(fetchAllCoachingSessions());
      }

      props.onClose(!openDialog);
      reset();
      resetBulkFields();
    } catch (error) {
      toast.error(`Error creating coaching  bulk sessions.\nMessage: ${error?.message}`);
    }
  };

  const getStageDuplications = (formValues: FieldValues, isSingleType: boolean): ICoachingSession[] => {
    setDuplicatedSessions([]);
    const existingSessions = coachingSessions
      .filter((s) => formValues.group.id === s.groupId)
      .filter((s) => SessionStatus.Cancelled !== s.status);

    let duplicates: ICoachingSession[] = [];
    if (isSingleType) {
      duplicates.push(
        existingSessions.find((s) => formValues.stage?.name === s.stage && SessionStage.Recurring !== s.stage)
      );
    } else {
      if (formValues.addMeetAndGreet) {
        duplicates.push(existingSessions.find((s) => SessionStage.MeetAndGreet === s.stage));
      }
      if (formValues.addFinalSession) {
        duplicates.push(existingSessions.find((s) => SessionStage.Final === s.stage));
      }
    }
    duplicates = duplicates.filter((s) => s);
    setDuplicatedSessions(duplicates);
    return duplicates;
  };

  const breakValidation = (formValues: FieldValues): boolean => {
    let breakValid: boolean = true;
    let sessionsNumber: number = 0;

    if (formValues.addMeetAndGreet) {
      sessionsNumber++;
    }
    if (formValues.addFinalSession) {
      sessionsNumber++;
    }

    sessionsNumber += +formValues.recSessionsCount;
    if (sessionsNumber < formValues.breakAfter) {
      breakValid = false;
    }

    return breakValid;
  };
  const resetBulkFields = (skipType?: boolean): void => {
    resetField('recSessionsCount');
    resetField('addMeetAndGreet');
    resetField('addFinalSession');
    resetField('breakDuration');
    resetField('interval');
    resetField('breakAfter');
    if (!skipType) {
      setType(SessionType.Single);
    }
  };

  return (
    <Dialog
      onClose={() => {
        props.onClose(!openDialog);
        resetBulkFields();
      }}
      open={openDialog}
      maxWidth="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id="simple-dialog-title"
          style={{ backgroundColor: Colors.BackgroundDrawer, color: Colors.TextElevated }}
        >
          {SessionType.Single === type ? 'Create Group Session' : 'Create Bulk Group Sessions'}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: Colors.TextElevated, mb: 4, mt: 1 }}>
            You are creating a session or sessions for all participants of a selected cohort
          </Typography>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={3}>
            <DialogContent>
              <div style={{ marginBottom: 25 }}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="groups"
                      onChange={(event, newInputValue: { id: string; name: string }) => {
                        field.onChange(newInputValue);
                      }}
                      options={groups}
                      getOptionLabel={(option: { id: string; description: string }) => option.description}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Cohort"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                  name="group"
                  control={control}
                />
              </div>

              <Box sx={{ mt: 2 }}>
                <>
                  <FormLabel id="type-radio">Session Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="type-radio"
                    name="type-radio"
                    value={type}
                    onChange={(event, newValue) => {
                      setType(newValue as SessionType);
                      setDuplicatedSessions([]);
                      resetBulkFields(true);
                    }}
                  >
                    <FormControlLabel value={SessionType.Single} control={<Radio color="success" />} label="Single" />
                    <FormControlLabel value={SessionType.Bulk} control={<Radio color="success" />} label="Bulk" />
                  </RadioGroup>
                </>
              </Box>

              {SessionType.Single === type && (
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="stages"
                      onChange={(event, newInputValue: { id: string; name: string }) => {
                        field.onChange(newInputValue);
                      }}
                      options={STAGES?.filter((s) => s.name !== SessionStage.Individual)}
                      getOptionLabel={(option: { id: string; name: string }) =>
                        option.name === 'Meet And Greet' ? 'First Session' : option.name
                      }
                      style={{ width: 300, marginTop: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Stage"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                  name="stage"
                  control={control}
                />
              )}

              {SessionType.Bulk === type && (
                <>
                  <Box sx={{ mt: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          inputProps={{ step: 1, min: 1, max: 100 }}
                          type="number"
                          label="Number of Recurring Sessions"
                          variant="outlined"
                          color="success"
                          required={true}
                          value={field.value}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (!isNullOrUndefined(newValue)) {
                              field.onChange(newValue);
                            }
                          }}
                        />
                      )}
                      name="recSessionsCount"
                      control={control}
                    />
                  </Box>
                  <Box display="block">
                    <Controller
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="success"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="Add First Session"
                        />
                      )}
                      name="addMeetAndGreet"
                      control={control}
                    />
                  </Box>
                  <Box display="block">
                    <Controller
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="success"
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="Add Final Session"
                        />
                      )}
                      name="addFinalSession"
                      control={control}
                    />
                  </Box>
                </>
              )}
            </DialogContent>

            <DialogContent style={{ marginTop: -10 }}>
              {/* Session Interval picker */}
              <DialogContent style={{ marginBottom: 20 }}>
                {SessionType.Bulk === type && (
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="interval"
                        onChange={(event, newInputValue: SessionIntervalInterface) => {
                          field.onChange(newInputValue);
                        }}
                        options={SESSION_INTERVALS}
                        getOptionLabel={(option: SessionIntervalInterface) => option.label}
                        style={{ width: 300, marginBottom: '10px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Interval"
                            variant="outlined"
                            color="success"
                            required={true}
                          />
                        )}
                      />
                    )}
                    name="interval"
                    control={control}
                  />
                )}
              </DialogContent>

              {/* Date picker */}
              <DialogContent style={{ marginTop: -30 }}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="datetime-local"
                      label="Date"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      style={{ width: 300, height: '56px', paddingTop: '5px', marginBottom: '10px' }}
                      variant="outlined"
                      color="success"
                      required={true}
                    />
                  )}
                  name="date"
                  control={control}
                  rules={{ required: true }}
                />
              </DialogContent>

              {/* Session Duration*/}
              <DialogContent style={{ marginTop: -10, marginBottom: 5 }}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="sessionDuration"
                      onChange={(event, newInputValue: SessionDurationInterface) => {
                        field.onChange(newInputValue);
                      }}
                      options={SESSION_DURATIONS}
                      getOptionLabel={(option: SessionDurationInterface) => option.label}
                      style={{ width: 300, marginTop: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Session Duration"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                  name="sessionDuration"
                  control={control}
                />
              </DialogContent>

              {/* Timezone picker */}
              <DialogContent>
                <Autocomplete
                  id="timezone"
                  onChange={(event, newInputValue: { timezone: string; name: string }) => setTz(newInputValue)}
                  defaultValue={tz}
                  options={timezones.map((v) => {
                    let obj = { timezone: '', name: '' };
                    obj.timezone = v;
                    obj.name = `${time(v)} - ${v}`;
                    return obj;
                  })}
                  getOptionLabel={(option: { timezone: string; name: string }) => option.name}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Timezone"
                      variant="outlined"
                      color="success"
                    />
                  )}
                />
              </DialogContent>
            </DialogContent>

            {SessionType.Bulk === type && (
              <DialogContent>
                {/* Session Break After */}
                <Typography sx={{ ml: 3, mb: 1 }}>Use options below to set cohort's break</Typography>
                <DialogContent>
                  <Box sx={{ mt: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          inputProps={{ step: 1, min: 0, max: 20 }}
                          type="number"
                          label="Number of Sessions before Break"
                          variant="outlined"
                          color="success"
                          value={field.value}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (!isNullOrUndefined(newValue)) {
                              field.onChange(newValue);
                              setHasBreakAfter(Number(newValue) ? true : false);
                            }
                          }}
                        />
                      )}
                      name="breakAfter"
                      control={control}
                    />
                  </Box>
                </DialogContent>

                {/* Break Duration*/}
                <DialogContent>
                  {SessionType.Bulk === type && (
                    <Controller
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          id="breakDuration"
                          onChange={(event, newInputValue: SessionBreakInterface) => {
                            field.onChange(newInputValue);
                          }}
                          options={SESSION_BREAK_DURATIONS}
                          getOptionLabel={(option: SessionBreakInterface) => option.label}
                          style={{ width: 300, marginTop: '10px' }}
                          disabled={!hasBreakAfter}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                shrink: true
                              }}
                              label="Break Duration"
                              variant="outlined"
                              color="success"
                              required={hasBreakAfter}
                              disabled={!hasBreakAfter}
                            />
                          )}
                        />
                      )}
                      name="breakDuration"
                      control={control}
                    />
                  )}
                </DialogContent>
              </DialogContent>
            )}
          </Stack>

          {/* Duplicated stages warning */}
          {!!duplicatedSessions?.length && (
            <Stack>
              <DialogContent>
                <WarningIcon style={{ verticalAlign: 'sub', color: '#b04b28', marginRight: '5px' }} />
                <Typography style={{ display: 'inline-block', color: '#b04b28' }}>
                  NOTE: This cohort already has following session(s) scheduled. Please confirm creating new sessions.
                </Typography>
                <ul style={{ marginLeft: '1rem' }}>
                  {duplicatedSessions.map((s) => (
                    <li key={s.id}>
                      <h3 style={{ color: '#b04b28', margin: 0 }}>
                        {s.stage === 'Meet And Greet' ? 'First Session' : s.stage} on{' '}
                        {new Date(s.date).toLocaleDateString()}, topic: "{s.topic || 'no topic'}", status: {s.status}
                      </h3>
                    </li>
                  ))}
                </ul>
              </DialogContent>
            </Stack>
          )}

          {/* Invalid number of sessions before break error */}
          {!breakValid && (
            <Stack>
              <DialogContent>
                <WarningIcon style={{ verticalAlign: 'sub', color: '#cc3300', marginRight: '5px' }} />
                <Typography style={{ display: 'inline-block', color: '#cc3300' }}>
                  Error: Number of sessions before break is higher than total sessions number
                </Typography>
              </DialogContent>
            </Stack>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: Colors.BackgroundMain, marginTop: 30 }}>
          <Button
            style={{ float: 'right', color: 'grey', margin: '20px' }}
            size="large"
            color="inherit"
            variant="outlined"
            onClick={() => {
              props.onClose(!openDialog);
              resetBulkFields();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ float: 'right', backgroundColor: Colors.ButtonGreen, margin: '20px' }}
            size="large"
            color="success"
            variant="contained"
            type="submit"
          >
            {duplicatedSessions?.length ? 'CONFIRM' : 'SUBMIT'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCoachingSessionEventForm;