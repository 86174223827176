import { AnyAction } from "redux";
import { MatchActionTypes } from "../../redux/actions/matches";

  const initialState = {
    matches: [],
    matchdiff: [],
  }

  export function MatchesReducer(state = initialState, action: AnyAction){

    if (!state) {
      return initialState;
    }

    switch(action.type){

      case MatchActionTypes.CREATE:
        return {
          ...state, 
          matches:  [action.payload,...state.matches]
        }

      case MatchActionTypes.FETCH: 
        return {
          ...state,
          matches: action.payload,
          matchdiff: state.matchdiff
        }
      
        case MatchActionTypes.DIFF: 
        return {
          ...state,
          matchdiff: action.payload
        }

      case MatchActionTypes.UPDATE: 
        return {
          ...state,
          matchdiff: state.matchdiff,
          sessions: state.matches.map((match) => {
            
            if(match.id === action.payload.id){
              match.status = action.payload.status;
              match.stage = action.payload.stage
            } 
            return match;
          })
      }
        
      default:
        return state;
    }
  }

  