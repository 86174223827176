import { AnyAction } from 'redux';
import { CoachesActionTypes } from '../actions/coaches';
import {CoacheesActionTypes} from "../actions/coachees";

const initialState = {
  coaches: [],
  coachesUsers: []
};

export function CoachesReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case CoachesActionTypes.FETCH:
      return {
        ...state,
        coaches: action.payload
      };

    case CoachesActionTypes.FETCH_COACHES_USERS:
      return {
        ...state,
        coachesUsers: action.payload
      };

    case CoachesActionTypes.UPDATE:
      return {
        ...state,
        coachesUsers: state.coachesUsers.map((coachesUser) => {
          if (coachesUser.id === action.payload.id) {
            coachesUser.disabled = action.payload.disabled;
            coachesUser.note = action.payload.note;
            coachesUser.linkedin = action.payload.linkedin;
            coachesUser.email2 = action.payload.email2;
          }
          return coachesUser;
        })
      };

    case CoachesActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        coachesUsers: state.coachesUsers.map((coachesUser) => {
          if (coachesUser.userId === action.payload.id) {
            coachesUser.email = action.payload.email;
          }
          return coachesUser;
        })
      };

    default:
      return state;
  }
}
