import { AnyAction } from 'redux';
import { SurveyActionTypes } from '../actions/surveysResponse';

const initialState = {
  surveysResponse: [],
  surveyResponsesClient: [],
  allSurveyResponsesByClient: []
};

export function SurveysResponseReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case SurveyActionTypes.FETCH_RESPONSE:
      return {
        ...state,
        surveysResponse: action.payload
      };

      case SurveyActionTypes.FETCH_USER_RESPONSES:
      return {
        ...state,
        surveysResponse: action.payload
      };

    case SurveyActionTypes.FETCH_CLIENT_SURVEY_RESPONSES:
      return {
        ...state,
        allSurveyResponsesByClient: action.payload.map((surveyResponse) => {
          return {
            date: surveyResponse.createdAt,
            email: surveyResponse.email,
            surveyId: surveyResponse.surveyId,
            ...surveyResponse.response
          };
        })
      };

    case SurveyActionTypes.FETCH_RESPONSE_TABLE:
      return {
        ...state,
        surveyResponsesClient: action.payload.map((surveyResponse) => {
          return { date: surveyResponse.createdAt, email: surveyResponse.email, ...surveyResponse.response };
        })
      };

    default:
      return state;
  }
}
