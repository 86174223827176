// Layout Types
import ResponsiveDrawer from './components/admin/ResponsiveDrawer';

// Route Views
import Dashboard from './components/admin/dashboard/Dashboard';
import TaskBoard from './components/admin/tasks/TasksBoard';
import Clients from './components/admin/mentorship/clients/Clients';
import Config from './components/admin/config/Config';
import CoachingSessions from './components/admin/mentorship/coaching-sessions/CoachingSessions';
import UserProfile from './components/admin/users/UserProfile';
import Users from './components/admin/users/Users';
import Cohorts from './components/admin/mentorship/groups/Cohorts';
import FullCalendarCoachingSessions from './components/admin/mentorship/coaching-sessions/calendar/FullCalendarCoachingSessions';
import CoachingSessionsList from './components/admin/mentorship/coaching-sessions/list/CoachingSessionsList';
import GroupTasks from './components/admin/mentorship/groups/groupResponses/GroupTasks';
import CoachingSessionsListFiltered
  from "./components/admin/mentorship/coaching-sessions/list/CoachingSessionsListFiltered";

export default [
  {
    path: '/',
    exact: true,
    layout: ResponsiveDrawer,
    component: Dashboard
  },
  {
    path: '/tasks',
    exact: true,
    layout: ResponsiveDrawer,
    component: TaskBoard
  },
  {
    path: '/clients/:id',
    layout: ResponsiveDrawer,
    component: Clients
  },
  {
    path: '/clients',
    layout: ResponsiveDrawer,
    component: Clients,
    exact: true
  },
  {
    path: '/config',
    layout: ResponsiveDrawer,
    component: Config
  },
  {
    path: '/coaching-sessions-calendar',
    layout: ResponsiveDrawer,
    component: FullCalendarCoachingSessions
  },
  {
    path: '/coaching-sessions-list',
    layout: ResponsiveDrawer,
    component: CoachingSessionsList
  },
  {
    path: '/coaching-sessions-list-filtered/:groupName/:groupId',
    layout: ResponsiveDrawer,
    component: CoachingSessionsListFiltered
  },
  {
    path: '/coaching-sessions',
    layout: ResponsiveDrawer,
    component: CoachingSessions
  },
  {
    path: '/users',
    layout: ResponsiveDrawer,
    component: Users,
    exact: true
  },
  {
    path: '/users/:id',
    component: UserProfile,
    layout: ResponsiveDrawer,
    exact: true
  },
  {
    path: '/cohorts',
    layout: ResponsiveDrawer,
    component: Cohorts
  },
  {
    path: '/responses/:groupName/:groupId',
    component: GroupTasks,
    layout: ResponsiveDrawer,
    exact: true
  }
];
