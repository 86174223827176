import { SessionBreakDurationWeek, SessionDurationMinutes, SessionIntervalWeek } from './coaching.enums';
import { SessionBreakInterface, SessionDurationInterface, SessionIntervalInterface } from './coaching.interface';

export const STAGES = [
  { id: '1', name: 'Meet And Greet' },
  { id: '7', name: 'Recurring Session' },
  { id: '6', name: 'Final Session' },
  { id: '8', name: 'Individual Session' }
];

export const SESSION_INTERVALS: SessionIntervalInterface[] = [
  { id: 1, value: SessionIntervalWeek.One, label: 'One Week' },
  { id: 2, value: SessionIntervalWeek.Two, label: 'Two Weeks' },
  { id: 3, value: SessionIntervalWeek.Three, label: 'Three Weeks' },
  { id: 4, value: SessionIntervalWeek.Four, label: 'Four Weeks' },
  { id: 5, value: SessionIntervalWeek.Five, label: 'Five Weeks' },
  { id: 6, value: SessionIntervalWeek.Six, label: 'Six Weeks' }
];

// export const API_KEY = 'AIzaSyCm1eCMpW_MIGvxr-bL0ZBetF_ovLffjjw';

export const SESSION_BREAK_DURATIONS: SessionBreakInterface[] = [
  { id: 1, value: SessionBreakDurationWeek.One, label: 'One Week' },
  { id: 2, value: SessionBreakDurationWeek.Two, label: 'Two Weeks' },
  { id: 3, value: SessionBreakDurationWeek.Three, label: 'Three Weeks' },
  { id: 4, value: SessionBreakDurationWeek.Four, label: 'Four Weeks' },
  { id: 5, value: SessionBreakDurationWeek.Five, label: 'Five Weeks' },
  { id: 6, value: SessionBreakDurationWeek.Six, label: 'Six Weeks' },
  { id: 7, value: SessionBreakDurationWeek.Seven, label: 'Seven Weeks' },
  { id: 8, value: SessionBreakDurationWeek.Eight, label: 'Eight Weeks' },
  { id: 9, value: SessionBreakDurationWeek.Nine, label: 'Nine Weeks' },
  { id: 10, value: SessionBreakDurationWeek.Ten, label: 'Ten Weeks' }
];

export const SESSION_DURATIONS: SessionDurationInterface[] = [
  { id: 1, value: SessionDurationMinutes.Thirty, label: '30 minutes' },
  { id: 11, value: SessionDurationMinutes.FortyFive, label: '45 minutes' },
  { id: 2, value: SessionDurationMinutes.Sixty, label: '1 hour' },
  { id: 3, value: SessionDurationMinutes.Ninety, label: '1 hour 30 minutes' },
  { id: 33, value: SessionDurationMinutes.HundredFive, label: '1 hour 45 minutes' },
  { id: 4, value: SessionDurationMinutes.HundredTwenty, label: '2 hours' }
];