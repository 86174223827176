import * as React from "react";
import { Provider } from "react-redux";

import AppBody from "./app";
import { BrowserRouter } from "./utils/Router";
import store from "./redux/store/index";

/**
 * Entrypoint/facility for Locelle's web admin app.
 */
class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppBody />
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App;
