import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AiOutlineClose } from 'react-icons/ai';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SurveysResponse from '../SurveysResponse';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import { Colors, UserType } from '../../../../enums/enums';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  surveyId: string;
  surveyType: string;
  title: string;
  target: UserType;
  surveyResponses: any;
}

const SurveyResponsesDialog: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="success" variant="outlined" onClick={handleClickOpen}>
        Open Response
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }} style={{ background: Colors.TextElevated }}>
          <Toolbar>
            <FormatListBulletedIcon />
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.title}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <AiOutlineClose />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SurveysResponse
          surveyResponses={props.surveyResponses}
          surveyId={props.surveyId}
          surveyType={props.surveyType}
          target={props.target}
        />
      </Dialog>
    </div>
  );
};

export default SurveyResponsesDialog;
