import { Rating, Typography } from '@mui/material';
import React from 'react';

const firstAssessmentColumns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  {
    name: 'company',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{v}</Typography>
          </>
        );
      }
    }
  },
  {
    name: 'title',
    label: 'Job Title',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <Typography fontSize="small">
            {v} at {x?.rowData[1]}
          </Typography>
        );
      }
    }
  },
  { name: 'email', label: 'Email' },
  {
    name: 'rating',
    label: 'Rating',
    options: {
      sort: true,
      customBodyRender: (v, x) => {
        return <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />;
      }
    }
  },
  { name: 'comment', label: 'Comments' }
];

export default firstAssessmentColumns;
