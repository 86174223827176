import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const newCoacheeOnboardingColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>
    }
  },
  {
    name: 'name',
    label: 'Name and Details',
    options: {
      sort: true,
      customBodyRender: (v, x) => (
        <ListItem sx={{mr: 3}}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography>
                  <b>{v}</b>, {x?.rowData[3]} years
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography component="span" color="text.primary">
                  {x?.rowData[4]} at {x?.rowData[5]}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  Experience: {x?.rowData[6]} years
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  Management Experience: ({x?.rowData[11] === 'Other' ? x?.rowData[12] : x?.rowData[11]})
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  <span>{x?.rowData[7] === 'Other' ? x?.rowData[8] : x?.rowData[7]}, </span>
                  <span> ({x?.rowData[9] === 'Other' ? x?.rowData[10] : x?.rowData[9]})</span>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }
  },
  {
    name: 'age',
    label: 'Age',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'clientName',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'ethnic',
    options: {
      display: false
    }
  },
  {
    name: 'otherEthnic',
    options: {
      display: false
    }
  },
  {
    name: 'pronoun',
    options: {
      display: false
    }
  },
  {
    name: 'otherPronoun',
    options: {
      display: false
    }
  },
  {
    name: 'managementExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherManagementExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherCareer',
    options: {
      display: false
    }
  },
  {
    name: 'otherLeadershipChallenges',
    options: {
      display: false
    }
  },
  {
    name: 'linkedin',
    label: 'LinkedIn Profile',
    options: {
      sort: true,
      customBodyRender: (v, x) => (
        <a href={v} target="_blank" rel="noreferrer">
          {v}
        </a>
      )
    }
  },
  {
    name: 'career',
    label: 'Career',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography>{v === 'Other' ? x?.rowData[13] : v}</Typography>
    }
  },
  {
    name: 'leadershipStyle',
    label: 'Leadership Style',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography sx={{ mr: -3 }}>{v}</Typography>
    }
  },
  {
    name: 'leadershipChallenges',
    label: 'Leadership Challenges',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{marginLeft: -35}}>
          {v?.map((leadershipChallenge) => (
            <li>
              <Typography fontSize="small">
                {leadershipChallenge === 'Other' ? x?.rowData[14] : leadershipChallenge}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'leadershipGoal',
    label: 'Goals as a Leader',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <Typography fontSize="small" sx={{ maxWidth: '290px', mr: -2, ml: -3 }}>
          {v}
        </Typography>
      )
    }
  },
  {
    name: 'coachingGoal',
    label: 'Expectations from Coaching',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <Typography fontSize="small" sx={{ maxWidth: '290px', mr: -2 }}>
          {v}
        </Typography>
      )
    }
  },
  {
    name: 'personality',
    label: 'Personality',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{marginLeft: -20}}>
          {v?.map((personality) => (
            <li>
              <Typography fontSize="small">{personality}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  }
];

export default newCoacheeOnboardingColumns;
