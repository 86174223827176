import { AnyAction } from "redux";
import { MentorActionTypes } from "../actions/mentors";

  const initialState = {
    mentors: [],
  }

  export function MentorsReducer(state = initialState, action: AnyAction){

    if (!state) {
      return initialState;
    }

    switch(action.type){

      case MentorActionTypes.CREATE:
        return {
          ...state, 
          mentors:  [state.mentors, action.payload]
        }

      case MentorActionTypes.FETCH: 
        return {
          ...state,
          mentors: action.payload
        }
        
      case MentorActionTypes.UPDATE: 
        return {
          ...state,
          mentors: state.mentors.map((mentor) => {
            
            if(mentor.id === action.payload.id){
              mentor.status = action.payload.status;
              mentor.note = action.payload.note;
            } 
            return mentor;
          })
        }

        case MentorActionTypes.UPDATE_EMAIL: 
          return {
          ...state,
          mentors: state.mentors.map((mentor) => {
            
            if(mentor.userId === action.payload.id){
              mentor.email = action.payload.email;
            } 
            return mentor;
          })
        }

        case MentorActionTypes.UPDATE_LINKEDIN:
          return {
          ...state,
          mentors: state.mentors.map((mentor) => {

            if(mentor.userId === action.payload.id){
              mentor.linkedin = action.payload.linkedin;
            }
            return mentor;
          })
        }
        
      default:
        return state;
    }
  }

  