import axios from 'axios';
import { sign } from 'jsonwebtoken';

const secret = process.env.REACT_APP_TOKEN_SECRET;

const loggerUser = localStorage.getItem('ADMIN:USER');

const token = sign({}, secret, {
  subject: `ADMIN:${loggerUser}`,
  expiresIn: '1d'
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export default api;
