import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Dispatch, SetStateAction } from 'react';
import Stack from '@mui/material/Stack';
import { Divider, Typography, Button } from '@mui/material';
import moment from 'moment-timezone';
import { format, parseISO } from 'date-fns';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { Colors } from '../../../../../enums/enums';

interface Props {
  openDialog: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  submitForm?: (clientId: any, matchId: any, description: any, stage: any, date: any, meetingId?: any) => void;
  selectedClient?: { id: string; name: string };
  selectedMatch?: { id: string; description: string };
  date?: Date;
  event?: any;
  updateSession: ({ id, date }: { id: any; date: any }) => Promise<void>;
}

const EditSessionEventForm = (props: Props) => {
  const openDialog = props.openDialog;
  const timezones = moment.tz.names();
  const [selectedDate, setSelectedDate] = React.useState(props?.event?.start);

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = (zone) => moment.tz(zone).format('Z');
  const [tz, setTz] = React.useState({ timezone: currentTimezone, name: currentTimezone });

  const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDate(event.target.value);
  };

  const closeDialog = (): void => {
    setSelectedDate(null);
    props.onClose(!openDialog);
  };

  const formatDate = props?.event && format(props?.event?.start, 'yyyy-MM-dd HH:mm');

  return (
    <Dialog onClose={() => props.onClose(!openDialog)} open={openDialog} maxWidth="xl">
      <DialogTitle style={{ backgroundColor: Colors.BackgroundDrawer }} id="simple-dialog-title">
        <ScheduleRoundedIcon
          style={{ color: 'green', verticalAlign: 'text-bottom', marginRight: 15, fontSize: '30px' }}
        />
        <Typography style={{ display: 'inline-block', fontSize: '20px', color: Colors.TextElevated }}>
          {props?.event?.title}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: Colors.BackgroundMainLighter }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <DialogContent>
            <DialogContent>
              <TextField
                id="datetime-local"
                label="Date"
                variant="outlined"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleDateChange}
                defaultValue={formatDate}
                style={{ width: 300, height: '56px', paddingTop: '5px' }}
              />
            </DialogContent>
            <DialogContent>
              <Autocomplete
                id="timezone"
                onChange={(event, newInputValue: { timezone: string; name: string }) => setTz(newInputValue)}
                defaultValue={tz}
                options={timezones.map((v) => {
                  let obj = { timezone: '', name: '' };
                  obj.timezone = v;
                  obj.name = `${time(v)} - ${v}`;
                  return obj;
                })}
                getOptionLabel={(option: { timezone: string; name: string }) => option.name}
                style={{ width: 300, marginTop: 20 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Timezone"
                    variant="outlined"
                    color="secondary"
                  />
                )}
              />
            </DialogContent>
          </DialogContent>
        </Stack>
      </DialogContent>
      <DialogActions style={{ backgroundColor: Colors.BackgroundDrawer }}>
        <Button
          variant="contained"
          color="success"
          type="submit"
          size="large"
          onClick={async () => {
            await props.updateSession({ id: props.event.id, date: selectedDate || props?.event?.start });
            closeDialog();
          }}
          sx={{ backgroundColor: Colors.ButtonGreen, m: 3, color: 'white' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSessionEventForm;