import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const classifications = [
  'buildingInfluence',
  'advancingCareer',
  'negotiating',
  'expressingYourself',
  'publicSpeaking',
  'leadership',
  'workLifeBalance',
  'buildingNetwork',
  'navigatingBoundaries',
  'managingEmotions',
  'navigatingDiscrimination',
  'managingConflict',
  'imposterSyndrome'
];

const classificationTableColumns = classifications.map((value) => {
  const column = {
    name: value,
    label: value,
    options: {
      display: false
    }
  };
  return column;
});

let menteeColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },

  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          zIndex: 100
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          zIndex: 100
        }
      }),
      customBodyRender: (v, x) => (
        <ListItem>
          {/* <ListItemAvatar>
            <Avatar sx={{ bgcolor: '#683364' }} >{v.charAt(0)}</Avatar>
          </ListItemAvatar> */}
          <ListItemText
            primary={`${v} (${x?.rowData[18] || 'n/a'})`}
            secondary={
              <React.Fragment>
                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                  {x?.rowData[3]}:
                </Typography>
                {` ${x?.rowData[4]} experience`}
                <br />
                {x?.rowData[5]}
                <br />
                {x?.rowData[17]}
              </React.Fragment>
            }
          />
        </ListItem>
      )
      //   hint: "?",
      //   setCellProps: () => ({ style: { whiteSpace: "nowrap" } })
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'age',
    label: 'Age'
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      display: false
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
            <Typography fontSize="small">{x?.rowData[9]}</Typography>
          </>
        );
      }
    }
  },

  {
    name: 'timezone',
    options: {
      display: false
    }
  },
  {
    name: 'goal',
    label: 'Change',
    options: {
      customBodyRender: (v, x) => <Typography fontSize="small">{v}</Typography>
    }
  },
  {
    name: 'personality',
    label: 'Personality',
    options: {
      customBodyRender: (v, x) => <Typography fontSize="small">{`${v?.join(',\n')}`}</Typography>
    }
  },
  {
    name: 'topics',
    label: 'Areas',
    options: {
      customBodyRender: (v, x) => (
        <>
          {v?.map((area) => (
            <Typography key={v} fontSize="small">
              {area}
            </Typography>
          ))}
          {<Typography fontSize="small">{x?.rowData[12]}</Typography>}
        </>
      )
    }
  },
  {
    name: 'otherTopic',
    label: 'Other',
    options: { customBodyRender: (v, x) => <Typography fontSize="small">{x?.rowData[13]}</Typography> }
  },
  {
    name: 'growth',
    label: 'Take Away',
    options: {
      customBodyRender: (v, x) => <Typography fontSize="small">{x?.rowData[14]}</Typography>
    }
  },
  {
    name: 'otherGrowth',
    label: 'Other',
    options: {
      customBodyRender: (v, x) => (
        <>
          <Typography fontSize="small">{x?.rowData[15]}</Typography>
        </>
      )
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'ethnic',
    options: {
      display: false
    }
  },
  {
    name: 'pronoum',
    options: {
      display: false
    }
  }
];

menteeColumns = menteeColumns.concat(classificationTableColumns);

menteeColumns = menteeColumns.concat([
  {
    name: 'leadership',
    label: 'Stats',
    options: {
      customBodyRender: (v, x) => (
        <>
          {classifications?.map((v, i) => (
            <Typography key={v} fontSize="small">{`${v}:${x?.rowData[i + 19]}`}</Typography>
          ))}
        </>
      )
    }
  },
  {
    name: 'becomeMentor',
    label: 'Become Mentor'
  }
]);

export default menteeColumns;
