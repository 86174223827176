import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { updateMentee } from '../../../../redux/actions/mentees';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

interface Props {
  open: boolean;
  selectedValue: string[];
  onClose: Dispatch<SetStateAction<boolean>>;
}

const MenteeEditDialog = (props: Props) => {
  const dispatch = useDispatch();

  const { onClose, selectedValue, open } = props;

  const [editing, setEditing] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleClose = () => {
    onClose(!open);
    setShowSuccess(false);
  };

  const submitForm = () => {
    const userId = props.selectedValue && props.selectedValue[10];
    try {
      dispatch(updateMentee({ id: userId, status, note }));
      setShowSuccess(true);
      setTimeout(handleClose, 1000);
      setEditing(false);
    } catch (error) {
      alert(error);
      setShowSuccess(false);
    }
  };

  let currentId,
    company,
    currentName,
    title,
    currentEmail,
    linkedin,
    timezone,
    currentStatus,
    currentNote,
    createdAt,
    userId;

  const [id, setId] = React.useState(null);
  const [note, setNote] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  React.useEffect(() => {
    if (selectedValue) {
      [
        currentId,
        company,
        currentName,
        title,
        currentEmail,
        linkedin,
        timezone,
        currentStatus,
        currentNote,
        createdAt,
        userId
      ] = selectedValue;
    }
    setNote(currentNote);
    setStatus(currentStatus);
    setName(currentName);
    setId(currentId);
    setEmail(currentEmail);
  }, [selectedValue]);

  const handleNoteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEditing(true);
    setNote(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  React.useEffect(() => {
    setShowSuccess(showSuccess);
    // setNote(currentNote);
  }, [showSuccess]);
  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title" style={{ color: '#9f298d' }}>
        Update Mentee
      </DialogTitle>
      <DialogContent>
        <TextField
          style={{ width: '300px' }}
          disabled
          id="outlined-disabled"
          label="Name"
          defaultValue={name}
          variant="filled"
        />
      </DialogContent>
      {/* <DialogContent>
        <TextField
          style={{ width: '300px'}}
          id="email"
          label="Email"
          defaultValue={email}
          onChange={(e)=> setEmail(e.target.value)}
          // variant="filled"
        />
      </DialogContent> */}
      <DialogContent>
        <FormControl>
          <InputLabel shrink id="status-label">
            Status
          </InputLabel>
          <Select value={`${status}`} onChange={handleChange} label="Status" style={{ width: '300px' }}>
            <MenuItem value={'NEW'}>NEW</MenuItem>
            <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
            <MenuItem value={'MATCHED'}>MATCHED</MenuItem>
            <MenuItem value={'PAUSED'}>PAUSED</MenuItem>
            <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <TextField
          id="note"
          label="Note"
          value={note}
          onChange={handleNoteChange}
          style={{ paddingTop: '5px', width: '300px' }}
          multiline
          rows={4}
        />
      </DialogContent>

      <DialogContent>
        {showSuccess && <Typography style={{ color: '#9f298d' }}>Mentee Updated</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          sx={{ backgroundColor: '#9f298d', mr: 2, mb: 2 }}
          onClick={submitForm}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenteeEditDialog;
