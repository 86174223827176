import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Colors } from '../../../enums/enums';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

interface IProps {
  onFileSelect: (files: FileList) => void;
}

export const InputFileUpload = (props: IProps) => {
  const { onFileSelect } = props;
  return (
    <Button
      component="label"
      variant="contained"
      color="success"
      sx={{ backgroundColor: Colors.ButtonGreen, width: '230px', height: '40px' }}
      startIcon={<CloudUploadIcon />}
    >
      Upload file
      <VisuallyHiddenInput
        type="file"
        onChange={(e) => {
          onFileSelect(e.target.files);
          e.target.value = null;
        }}
      />
    </Button>
  );
};
