import * as React from 'react';
import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from '../../utils/refreshToken';
import '../../css/Login.css';
import { Grid, Card } from '@material-ui/core';
import Logo from '../../assets/logo.png';
import { Colors } from '../../enums/enums';
import { Button } from '@mui/material';
const clientId = '854024493566-g7dsph1vobtbo8siqu8as9mscopu6f64.apps.googleusercontent.com';

function NewLogin(props) {
  const onSuccess = async (res) => {
    console.log('GOOGLE -> ' + JSON.stringify(res));
    const emailDomain = res.profileObj.email.split('@')[1];
    const allowedDomains = ['locelle.com', 'besthuman.io'];
    if (!allowedDomains.includes(emailDomain)) {
      alert('Please authenticate with your Locelle or BestHuman email');
      return;
    }

    localStorage.setItem('ADMIN:token', res.accessToken);
    localStorage.setItem('ADMIN:USER', res.profileObj.email);
    props.isSignedIn(true);

    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    alert(`Failed to login.`);
  };

  return (
    <div
      style={{
        backgroundColor: Colors.BackgroundMain,
        width: '100%',
        height: '100%',
        marginTop: '-8px',
        marginLeft: '-8px',
        position: 'absolute'
      }}
    >
      <Card
        elevation={6}
        style={{
          backgroundColor: Colors.BackgroundMainLighter,
          margin: '10% auto',
          width: '450px',
          height: '300px',
          borderRadius: '10px'
        }}
      >
        <img src={Logo} alt="logo" className="logo" />
        <br />
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={6} className="gd">
              {' '}
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={false}
                variant="contained"
                color="success"
                className="btn"
                size="large"
              >
                Sign In
              </Button>
            )}
            buttonText="Login with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            // responseType='code'
            cookiePolicy={'single_host_origin'}
            style={{ marginTop: '100px' }}
            isSignedIn={false}
            scope="openid email profile https://www.googleapis.com/auth/calendar"
            accessType="offline"
          />
        </form>
      </Card>
      <br />
    </div>
  );
}

export default NewLogin;