import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const newCoachOnboardingColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          left: '0'
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name and Details',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          left: '0'
        }
      }),
      customBodyRender: (v, x) => (
        <ListItem sx={{ mx: -2 }}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography>
                  <b>{v}</b>, {x?.rowData[3]} years
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography >
                  {x?.rowData[4]} at {x?.rowData[5]}
                </Typography>
                <Typography  component="span" variant="body2" color="text.primary">
                  Experience: {x?.rowData[6]} years
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  Management Experience: {x?.rowData[11] === 'Other' ? x?.rowData[12] : x?.rowData[11]}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  <span>{x?.rowData[7] === 'Other' ? x?.rowData[8] : x?.rowData[7]}, </span>
                  <span> ({x?.rowData[9] === 'Other' ? x?.rowData[10] : x?.rowData[9]})</span>
                </Typography>
                <br />
                <br />
                <Typography component="span" variant="body2" color="text.primary">
                  <a href={x?.rowData[18]} target="_blank" rel="noreferrer">
                    {x?.rowData[18]}
                  </a>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }
  },
  {
    name: 'age',
    label: 'Age',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'clientName',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'ethnic',
    options: {
      display: false
    }
  },
  {
    name: 'otherEthnic',
    options: {
      display: false
    }
  },
  {
    name: 'pronoun',
    options: {
      display: false
    }
  },
  {
    name: 'otherPronoun',
    options: {
      display: false
    }
  },
  {
    name: 'managementExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherManagementExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherIndustriesExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherRoleExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherLeadershipExperience',
    options: {
      display: false
    }
  },
  {
    name: 'otherCoachingStyle',
    options: {
      display: false
    }
  },
  {
    name: 'otherExperienceExtent',
    options: {
      display: false
    }
  },
  {
    name: 'linkedin',
    label: 'LinkedIn Profile',
    options: {
      display: false
    }
  },
  {
    name: 'timeCommitment',
    label: 'Time Commitment',
    options: {
      display: false
    }
  },
  {
    name: 'training',
    label: 'Additional Training',
    options: {
      display: false
    }
  },
  {
    name: 'otherTraining',
    label: 'Additional Training Other',
    options: {
      display: false
    }
  },
  {
    name: 'industriesExperience',
    label: 'Industries Experience',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -45 }}>
          {v?.map((industryExperience) => (
            <li>
              <Typography fontSize="small">
                {industryExperience === 'Other' ? x?.rowData[13] : industryExperience}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'roleExperience',
    label: 'Roles Experience',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -45, marginRight: -25 }}>
          {v?.map((industryExperience) => (
            <li>
              <Typography fontSize="small">
                {industryExperience === 'Other' ? x?.rowData[14] : industryExperience}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'leadershipExperience',
    label: 'Leadership Experience Level',
    options: {
      sort: false,
      customBodyRender: (v, x) => <Typography fontSize="small">{v === 'Other' ? x?.rowData[15] : v}</Typography>
    }
  },
  {
    name: 'credentials',
    label: 'Coaching Credentials',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <Typography fontSize="small" sx={{ maxWidth: '290px', ml: -4, mr: -2 }}>
          {v}
        </Typography>
      )
    }
  },
  {
    name: 'numberOfCoachingHours',
    label: 'Coaching Hours'
  },
  {
    name: 'experienceExtent',
    label: 'Extent of Coaching Experience',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -45 }}>
          {v?.map((option) => (
            <li>
              <Typography fontSize="small">{option === 'Other' ? x?.rowData[17] : option}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'coachingStyle',
    label: 'Coaching Style',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -40 }}>
          {v?.map((option) => (
            <li>
              <Typography fontSize="small">{option === 'Other' ? x?.rowData[16] : option}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'groupCoaching',
    label: 'Coaching Sessions',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <>
          <Typography sx={{ ml: -2, mb: 2 }} variant="body2" color="text.primary">
            Group coaching: {v}
          </Typography>
          <Typography sx={{ ml: -2, mb: 2 }} variant="body2" color="text.primary">
            Commitment: {x?.rowData[19]}
          </Typography>
          <Typography sx={{ ml: -2, mb: 2 }} variant="body2" color="text.primary">
            Additional training: {x?.rowData[20] === 'Other' ? x?.rowData[21] : x?.rowData[20]}
          </Typography>
        </>
      )
    }
  }
];

export default newCoachOnboardingColumns;
