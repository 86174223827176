import React, { Dispatch, SetStateAction } from 'react';
import { Button, DialogContent, Dialog, DialogActions, DialogTitle, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Colors } from '../../../../enums/enums';
import { deleteCohort } from '../../../../redux/actions/groups';
import { useForm } from 'react-hook-form';

interface Props {
  open: boolean;
  cohortId: string;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const DeleteCohortDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { open, cohortId, onClose } = props;

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      client: null,
      leaderId: null,
      coLeaderId: null,
      participants: [],
      description: null
    }
  });

  const handleClose = () => {
    onClose(false);
  };

  const onSubmit = async () => {
    try {
      dispatch(deleteCohort(cohortId));
      handleClose();
    } catch (e) {
      console.error('Error deleting cohort: ', e);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle sx={{ color: '#bd0c0c', backgroundColor: Colors.Inactive }}>Delete Cohort?</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: '600px', backgroundColor: Colors.BackgroundMain }}>
            <Typography sx={{ mt: 1, fontSize: '1.1rem', fontWeight: 'bold' }}>CAUTION:</Typography>
            <Typography sx={{ mt: 3, fontSize: '1rem' }}>
              Deleting this cohort will permanently remove all its data, sessions and user responses.
            </Typography>
            <Typography sx={{ mt: 3, fontSize: '1rem' }}>
              Google Calendar events will be deleted as well if exist.
            </Typography>
            <Typography sx={{ mt: 3, fontSize: '1rem', mb: 2 }}>This action cannot be undone.</Typography>
          </DialogContent>

          <DialogActions style={{ backgroundColor: Colors.Inactive }}>
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: 'grey', mr: 2, mb: 2, mt: 2 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color="error" sx={{ mr: 2, mb: 2, mt: 2 }} type="submit">
                Delete Cohort
              </Button>
            </>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default DeleteCohortDialog;
