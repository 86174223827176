import React from 'react';
import { ListItem, ListItemText, Typography, Avatar, ListItemAvatar, Stack } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {Colors} from "../enums/enums";

const termsAndConditionsColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography>{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          left: '0',
          zIndex: 100
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      customBodyRender: (v, x) => (
        <ListItem sx={{ mx: -2 }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: Colors.TextElevated }}>{v?.charAt(0)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={v} />
        </ListItem>
      )
    }
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      customBodyRender: (v, x) => <Typography>{v}</Typography>
    }
  },

  {
    name: 'agreeToTermsAndConditions',
    label: 'Terms and Conditions Agreement',
    options: {
      customBodyRender: (v, x) => (
        <Stack direction="row" spacing={1}>
          <RadioButtonCheckedIcon color="success" />
          <Typography>"I {v}"</Typography>
        </Stack>
      )
    }
  }
];

export default termsAndConditionsColumns;
