import api from '../../services/api';
import { toast } from 'react-toastify';

export enum CoacheesActionTypes {
  FETCH = 'COACHEES_FETCH',
  FETCH_COACHEES_USERS = 'COACHEES_USERS_FETCH',
  UPDATE = 'COACHEES_UPDATE',
  UPDATE_EMAIL = 'COACHEES_UPDATE_EMAIL'
}

export function fetchCoachees() {
  return function (dispatch) {
    return api.get('/coachees').then(({ data }) => {
      dispatch({
        type: CoacheesActionTypes.FETCH,
        payload: data
      });
    });
  };
}
export function fetchCoacheesUsers() {
  return function (dispatch) {
    return api.get('/coachees/coacheeUsers').then(({ data }) => {
      dispatch({
        type: CoacheesActionTypes.FETCH_COACHEES_USERS,
        payload: data
      });
    });
  };
}

export function updateCoachee(coachee: {
  userId: string;
  status?: string;
  note?: string;
  linkedin?: string;
  email2?: string;
}) {
  return function (dispatch) {
    return api
      .patch('/coachees', coachee)
      .then(({ data }) => {
        dispatch({
          type: CoacheesActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated coachee details');
      })
      .catch((e) => toast.error(`Error updating coachee, ${e}`));
  };
}

// selectors
export const getCoachees = (state) => state.coachees.coachees;
export const getCoacheesUsers = (state) => state.coachees.coacheesUsers;
export const getCoacheesByClientId = (id) => (state) =>
  state.coachees.coachees.filter((coachee) => coachee.clientId === id);
export const getCoacheesUsersByClientId = (id) => (state) =>
  state.coachees.coacheesUsers.filter((coacheeUser) => coacheeUser.clientId === id);
