import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Box, Button, Card, Stack, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCurrentClients, getClients } from '../../../../redux/actions/clients';
import Surveys from './ClientSurveysList';
import EditClientDialog from './EditClientDialog';
import IClient from './IClient';
import UsersList from './ClientUsersList';
import { Colors } from '../../../../enums/enums';
import { fetchCurrentUsers, getUsers } from '../../../../redux/actions/users';
import { fetchAllClientSessionsById, getAllClientSessions } from '../../../../redux/actions/coachingSessions';

type Props = {
  client?: any;
};

const ClientProfile = (props: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const users = useSelector(getUsers);
  const clientSessions = useSelector(getAllClientSessions);
  const usersFilteredByClient = users.filter((user) => user.clientId === id);

  const clients = useSelector(getClients);

  React.useEffect(() => {
    dispatch(fetchCurrentClients());
    dispatch(fetchCurrentUsers());
    dispatch(fetchAllClientSessionsById(id));
  }, [dispatch, id]);

  const client: IClient = clients.find((client) => client.id === id);
  const [updateClientDialog, setUpdateClientDialog] = useState(false);

  console.log(process.env.NODE_ENV);

  const portalUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : window.location.href.split('.').includes('stg')
      ? 'https://portal.stg.locelle.com'
      : 'https://portal.locelle.com';

  return (
    <div>
      <Card elevation={6} style={{ marginTop: '10px' }}>
        <Typography style={{ background: Colors.TextElevated, color: '#fefefe' }} align="center">
          {client?.name} Info
        </Typography>
        <Grid container style={{ padding: '15px', marginLeft: '15px' }}>
          <Grid item xs={4}>
            Contact Name:<Typography>{client?.contactName}</Typography>
          </Grid>
          <Grid item xs={4}>
            Current Subscription Start Date:<Typography> {new Date(client?.startDate).toDateString()}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1} display="flex" alignItems="center">
              <Typography style={{ color: Colors.ButtonGreen }}>New User Onboard Link</Typography>
              <Tooltip placement="top" arrow title="Copy link to clipboard">
                <Button
                  sx={{ backgroundColor: Colors.ButtonGreen, height: '25px' }}
                  variant="contained"
                  color="success"
                  onClick={() => navigator.clipboard.writeText(`${portalUrl}/${client?.code}/all`)}
                >
                  Copy
                </Button>
              </Tooltip>
            </Stack>
            <Typography sx={{ mt: 1 }}>
              <a target="_blank" href={`${portalUrl}/${client?.code}/all`}>{`${portalUrl}/${client?.code}/all`}</a>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '25px' }}>
            Contact Email:<Typography> {client?.contactEmail}</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px' }}>
            Current Subscription End Date:<Typography> {new Date(client?.endDate).toDateString()}</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px' }}>
            Status:<Typography> {client?.status}</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px', color: Colors.TextElevated }}>
            <b>Number of Users: {usersFilteredByClient?.length}</b>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: '20px', color: Colors.TextElevated }}>
            <b>Total Number of Sessions: {clientSessions}</b>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
          <Button
            variant="contained"
            color="success"
            sx={{
              background: Colors.ButtonGreen,
              marginBottom: '20px',
              marginLeft: '20px',
              height: '45px',
              marginTop: '10px'
            }}
            onClick={() => {
              setUpdateClientDialog(true);
            }}
          >
            Update Client
          </Button>
        </Box>
      </Card>
      <Box>
        <Card elevation={6} style={{ marginTop: '10px' }}>
          <Typography style={{ background: Colors.TextElevated, color: '#fefefe' }} align="center">
            Surveys
          </Typography>
          <Surveys clientId={id} />
        </Card>
      </Box>
      <Box>
        <Card elevation={6} style={{ marginTop: '10px' }}>
          <Typography style={{ background: Colors.TextElevated, color: '#fefefe' }} align="center">
            {client?.name} Users
          </Typography>
          <UsersList clientId={client?.id} />
        </Card>
      </Box>
      <EditClientDialog client={client} open={updateClientDialog} onClose={setUpdateClientDialog} />
    </div>
  );
};

export default ClientProfile;
