import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { fetchClientSurveys, getSurveysByClient } from '../../../../redux/actions/surveys';
import SurveysResponse from '../SurveysResponse';
import { SurveyTargetType, UserType } from '../../../../enums/enums';
import SurveyResponsesDialog from '../surveys/SurveyResponsesDialog';
import {
  fetchAllSurveysResponsesByClient,
  getAllSurveysResponsesByClient
} from '../../../../redux/actions/surveysResponse';

interface Props {
  clientId?: string;
}

const ClientSurveysList: React.FC<Props> = ({ clientId }: Props) => {
  const dispatch = useDispatch();

  const clientSurveys = useSelector(getSurveysByClient);
  const clientSurveyResponses = useSelector(getAllSurveysResponsesByClient);
  console.log('CLIENT RESPONSES', clientSurveyResponses);
  const surveyIdsWithResponses: string[] = clientSurveyResponses?.map((response) => response.surveyId);

  React.useEffect(() => {
    dispatch(fetchClientSurveys(clientId));
    dispatch(fetchAllSurveysResponsesByClient(clientId));
  }, [dispatch, clientId]);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [editing, setEditing] = React.useState(false);
  const [status, setStatus] = React.useState(null);

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sort: true
      }
    },

    {
      name: 'target',
      label: 'Target',
      options: {
        //   empty: true,
        customBodyRender: (v, x) => (
          <Chip color={SurveyTargetType.Mentors === v ? 'secondary' : 'primary'} variant="outlined" label={v} />
        )
      }
    },
    {
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (v, x) => {
          const userType = x?.rowData ? (x.rowData[3] as UserType) : null;
          return (
            <SurveyResponsesDialog
              title={`${x.rowData[1]} -  ${x.rowData[2]}`}
              surveyResponses={clientSurveyResponses?.filter((sr) => sr.surveyId === v)}
              surveyId={x.rowData[0]}
              surveyType={x.rowData[1]}
              target={userType}
            />
          );
        }
      }
    }
  ];

  if (editing) {
    columns.push({
      name: 'id',
      label: ' ',
      options: {
        customBodyRender: (v, x) =>
          x.rowIndex === selectedRow && (
            <SaveOutlinedIcon
              onClick={() => {
                x.rowData.push(status);
                console.log(x);
                setEditing(false);
              }}
            />
          )
      }
    });
  }

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: 'none',
    expandableRows: true,
    renderExpandableRow: (rowData: any[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      console.log(rowData, rowMeta);
      const userType = rowData ? (rowData[2]?.props?.label as UserType) : null;
      return (
        <>
          <tr>
            <td colSpan={12}>
              <SurveysResponse
                surveyId={rowData[0]}
                surveyResponses={clientSurveyResponses?.filter((sr) => sr.surveyId === rowData[0])}
                checked={rowData[0] !== ''}
                surveyType={rowData[1]}
                target={userType}
              />
            </td>
          </tr>
        </>
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => clientSurveys[d.dataIndex]._id); // array of all ids to to be deleted
      console.log(rowsDeleted, idsToDelete);
      // http.delete(idsToDelete, res).then(window.alert('Deleted!')); // your delete request here
    },

    grouping: {
      columnIndexes: [1, 3]
    },
    onRowClick: (rowData, rowState) => {
      console.log(rowData, rowState);
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <MUIDataTable
        title={''}
        data={clientSurveys?.filter((survey) => surveyIdsWithResponses?.includes(survey?.id))}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default ClientSurveysList;
