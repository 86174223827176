import * as React from 'react';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import CalendarViewDayOutlinedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CoachingSessionsList from './list/CoachingSessionsList';
import FullCalendarCoachingSessions from './calendar/FullCalendarCoachingSessions';

const CoachingSessions: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div style={{ padding: '8px' }}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChangeValue} style={{ backgroundColor: '#fff', color: 'black' }}>
          <Tab label="Calendar" icon={<CalendarTodayOutlinedIcon />} />
          <Tab label="Coaching Sessions List" icon={<CalendarViewDayOutlinedIcon />} />
        </Tabs>
      </AppBar>

      <TabPanel key="sessionList" value={value} index={1}>
        <CoachingSessionsList />
      </TabPanel>
      <TabPanel value={value} index={0}>
        {/* <SessionsCalendar/> */}
        <FullCalendarCoachingSessions />
      </TabPanel>
    </div>
  );
};

export default CoachingSessions;
