import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';

const newMenteeOnboardingColumns = [
  {
    name: 'id',
    options: {
      display: false
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
    }
  },
  {
    name: 'name',
    label: 'Name and Details',
    options: {
      sort: true,
      customBodyRender: (v, x) => (
        <ListItem sx={{ mx: -3 }}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography>
                  <b>{v}</b>, {x?.rowData[3]} years
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography sx={{ display: 'inline' }} color="text.primary">
                  {x?.rowData[4]} at {x?.rowData[5]}
                </Typography>
                <br />
                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                  Experience: {x?.rowData[6]} years
                </Typography>
                <br />
                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                  <span>{x?.rowData[7] === 'Other' ? x?.rowData[8] : x?.rowData[7]}, </span>
                  <span> ({x?.rowData[9] === 'Other' ? x?.rowData[10] : x?.rowData[9]})</span>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    }
  },
  {
    name: 'age',
    label: 'Age',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false
    }
  },
  {
    name: 'clientName',
    label: 'Company',
    options: {
      display: false
    }
  },
  {
    name: 'experience',
    label: 'Experience',
    options: {
      display: false
    }
  },
  {
    name: 'ethnic',
    options: {
      display: false
    }
  },
  {
    name: 'otherEthnic',
    options: {
      display: false
    }
  },
  {
    name: 'pronoun',
    options: {
      display: false
    }
  },
  {
    name: 'otherPronoun',
    options: {
      display: false
    }
  },
  {
    name: 'linkedin',
    label: 'LinkedIn Profile',
    options: {
      sort: false,
      customBodyRender: (v, x) => <a href={v}>{v}</a>
    }
  },
  {
    name: 'goal',
    label: 'Goals and Objectives',
    options: {
      sort: false,
      customBodyRender: (v, x) => <Typography sx={{ maxWidth: '400px', mr: -3 }}>{v}</Typography>
    }
  },
  {
    name: 'personality',
    label: 'Personality',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul style={{ marginLeft: -25 }}>
          {v?.map((personality) => (
            <li>
              <Typography>{personality}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  },
  {
    name: 'motivation',
    label: 'Expectations From Mentorship',
    options: {
      sort: false,
      customBodyRender: (v, x) => (
        <ul>
          {v?.map((motivation) => (
            <li>
              <Typography>{motivation}</Typography>
            </li>
          ))}
        </ul>
      )
    }
  }
];

export default newMenteeOnboardingColumns;
