import { AnyAction } from 'redux';
import { MentorshipSessionActionTypes } from '../actions/mentorshipSessions';

const initialState = {
  sessions: []
};

export function MentorshipSessions(state = initialState, action: AnyAction) {
  switch (action.type) {
    case MentorshipSessionActionTypes.CREATE:
      return {
        ...state,
        sessions: [...state.sessions, action.payload]
      };

    case MentorshipSessionActionTypes.FETCH:
      return {
        ...state,
        sessions: action.payload
      };

    case MentorshipSessionActionTypes.UPDATE:
      console.log('SESSIONS REDUCER ', state, action.payload);
      return {
        ...state,
        sessions: state.sessions.map((session) =>
          session.id === action.payload.id
            ? {
                ...session,
                date: action.payload.date.toString(),
                status: action.payload.status
              }
            : session
        )
      };

    case MentorshipSessionActionTypes.DELETE:
      console.log('SESSIONS REDUCER ', state, action.payload);
      const idOfDeletedSession = action.payload;
      return {
        ...state,
        sessions: state.sessions.filter((session) => session.id !== idOfDeletedSession)
      };

    default:
      return state;
  }
}
