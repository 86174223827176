import api from '../../services/api';
import { toast } from 'react-toastify';
import { ISession, ISessionBulk } from '../../components/admin/mentorship/sessions/session.models';

export enum MentorshipSessionActionTypes {
  CREATE = 'SESSION_CREATE',
  FETCH = 'SESSION_FETCH',
  UPDATE = 'SESSION_UPDATE',
  DELETE = 'SESSION_DELETE'
}

// Create single session
export function createSession(session: ISession) {
  return function (dispatch) {
    return api
      .post('/sessions', session)
      .then(({ data }) => {
        dispatch({
          type: MentorshipSessionActionTypes.CREATE,
          payload: data
        });
      })
      .catch((error) => createSessionErrorHandler(error));
  };
}
// Create bulk sessions
export function createBulkSession(session: ISessionBulk) {
  console.log('session: ', session);

  return function (dispatch) {
    const url = `/sessions/bulk`;
    return api
      .post(url, session)
      .then(() => {
        // do nothing as the Caller will re-fetch sessions
      })
      .catch((error) => createSessionErrorHandler(error));
  };
}

export function fetchAllSessions() {
  return function (dispatch) {
    return api.get('/sessions').then(({ data }) => {
      dispatch({
        type: MentorshipSessionActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function updateSession(match) {
  return function (dispatch) {
    return api
      .patch('/sessions', match)
      .then(({ data }) => {
        dispatch({
          type: MentorshipSessionActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated session');
      })
      .catch((error) => {
        console.error('Error updating session', error);
        toast.error(
          `Error updating session.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

export function deleteSession(sessionId: string) {
  return function (dispatch) {
    const url = `sessions/${sessionId}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: MentorshipSessionActionTypes.DELETE,
            payload: sessionId
          });
          toast.success('Successfully deleted session');
        } else {
          toast.error(`Error deleting session.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting session', error);
        toast.error(
          `Error deleting session.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

const createSessionErrorHandler = (error) => {
  console.log('Error creating session(s): ', error);
  throw error;
};

// selectors
export const getSessions = (state) => state.sessions.sessions;
