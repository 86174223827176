import api from '../../services/api';
import { toast } from 'react-toastify';

export enum CoachesActionTypes {
  FETCH = 'COACHES_FETCH',
  UPDATE = 'COACHES_UPDATE',
  UPDATE_EMAIL = 'COACHES_UPDATE_EMAIL',
  FETCH_COACHES_USERS = 'COACHES_USERS_FETCH'
}

export function updateCoach(coach: {
  userId: string;
  status?: string;
  note?: string;
  linkedin?: string;
  email2?: string;
}) {
  return function (dispatch) {
    return api
      .patch('/coaches', coach)
      .then(({ data }) => {
        dispatch({
          type: CoachesActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated coach');
      })
      .catch((e) => toast.error(`Error updating coach, ${e?.response?.data?.message}`));
  };
}

export function fetchCoachesUsers() {
  return function (dispatch) {
    return api.get('/coaches/coachesUsers').then(({ data }) => {
      dispatch({
        type: CoachesActionTypes.FETCH_COACHES_USERS,
        payload: data
      });
    });
  };
}

// selectors
export const getCoachesUsers = (state) => state.coaches.coachesUsers;
