import api from '../../services/api';

export enum SurveyActionTypes {
  FETCH = 'SURVEY_FETCH',
  FETCH_CLIENT_SURVEYS = 'FETCH_CLIENT_SURVEYS'
}

export function fetchCurrentSurveys() {
  return function (dispatch) {
    return api.get('/surveys').then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchClientSurveys(clientId) {
  return function (dispatch) {
    return api.get(`/surveys/${clientId}`).then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_CLIENT_SURVEYS,
        payload: data
      });
    });
  };
}

// selectors
export const getSurveys = (state) => state.surveys.surveys;
export const getSurveysActiveClients = (state) =>
  state.surveys.surveys.filter((survey) => survey.client.status === 'ACTIVE');
export const getSurveysByClient = (state) => state.surveys.clientSurveys;
