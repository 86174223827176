import api from '../../services/api';

export enum MenteeActionTypes {
  CREATE = 'MENTEE_CREATE',
  FETCH = 'MENTEE_FETCH',
  UPDATE = 'MENTEE_UPDATE',
  UPDATE_EMAIL = 'MENTEE_UPDATE_EMAIL',
  UPDATE_LINKEDIN = 'MENTEE_UPDATE_LINKEDIN'
}

// action creators
export function createMentee(match) {
  return function (dispatch) {
    return api.post('/mentees', match).then(({ data }) => {
      dispatch({
        type: MenteeActionTypes.CREATE,
        payload: data
      });
    });
  };
}

export function fetchCurrentMentees() {
  return function (dispatch) {
    return api.get('/mentees').then(({ data }) => {
      dispatch({
        type: MenteeActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function updateMentee(mentee: { id: string; status: string; note: string }) {
  return function (dispatch) {
    return api.patch('/mentees', mentee).then(({ data }) => {
      dispatch({
        type: MenteeActionTypes.UPDATE,
        payload: data
      });
    });
  };
}

// selectors
export const getMentees = (state) => state.mentees.mentees;
export const getActiveMentees = (state) =>
  state.mentees.mentees.filter((mentee) => mentee.status === 'ACTIVE' || mentee.status === 'NEW');
export const getMenteesByClientId = (id) => (state) => state.mentees.mentees.filter((mentee) => mentee.clientId === id);
